import { TableCell, TableHead, TableRow } from "@mui/material";

interface Data {
  id: number;
  deviceName: string;
  tagName: string;
  smsStatus: boolean;
  emailStatus: boolean;
  level1: number;
  level2: number;
  level3: number;
  action: string;
}

// function createData(
//   id: number,
//   name: string,
//   email: string,
//   status: string
// ): Data {
//   return {
//     id,
//     name,
//     email,
//     action: "view",
//   };
// }

const headCells: { id: keyof Data; disablePadding: boolean; label: string }[] =
  [
    {
      id: "deviceName",
      disablePadding: true,
      label: "DeviceName",
    },
    {
      id: "tagName",
      disablePadding: false,
      label: "TagName",
    },
    {
      id: "level1",
      disablePadding: false,
      label: "Level 1",
    },
    {
      id: "level2",
      disablePadding: false,
      label: "Level 2",
    },
    {
      id: "level3",
      disablePadding: false,
      label: "Level 3",
    },
    {
      id: "smsStatus",
      disablePadding: false,
      label: "SMS Status",
    },
    {
      id: "emailStatus",
      disablePadding: false,
      label: "Email Status",
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action",
    },
  ];

export function EnhancedTableHead() {
  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="normal"
            sx={{ fontWeight: 600, color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
