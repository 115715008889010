import { Device, PaginatedDevice } from "../../types/DeviceTypes";

export interface DeviceState {
  devices: PaginatedDevice;
}

// Update DeviceAction to include "DELETE_DEVICES"

export type DeviceAction =
  | { type: "SET_DEVICES"; payload: PaginatedDevice }
  | { type: "SET_DEVICES_WITH_PAGE"; payload: PaginatedDevice }
  | { type: "UPDATE_DEVICES_STATUS"; payload: { id: number; status: boolean } }
  | { type: "ADD_DEVICES"; payload: { devices: Device; addTotalItems: number } }
  | {
      type: "UPDATE_DEVICES";
      payload: { id: number | undefined; devices: Device };
    };

export default function DeviceReducer(
  state: DeviceState,
  action: DeviceAction
): DeviceState {
  const { type, payload } = action;
  switch (type) {
    case "SET_DEVICES":
      return { ...state, devices: payload };
    case "SET_DEVICES_WITH_PAGE":
      return { ...state, devices: payload };
    case "ADD_DEVICES":
      const { devices: newDevice, addTotalItems } = payload;
      return {
        ...state,
        devices: {
          ...state.devices,
          devices: [newDevice, ...state.devices.devices],
          totalItems: addTotalItems + 1,
        },
      };

    case "UPDATE_DEVICES_STATUS":
      const { id, status } = payload;

      const deviceToUpdate = state.devices.devices.find(
        (device) => device.id === id
      );

      if (deviceToUpdate) {
        deviceToUpdate.status = status;

        // Clone the devices array to trigger a state update
        const updatedDevicesAfterStatusUpdate = [...state.devices.devices];

        // Update the state with the new devices array
        return {
          ...state,
          devices: {
            ...state.devices,
            devices: updatedDevicesAfterStatusUpdate,
          },
        };
      }

      return state;

    case "UPDATE_DEVICES":
      const { id: updateId, devices } = payload;
      const updatedDevices = state.devices.devices.map((device) =>
        device.id === updateId ? { ...device, ...devices } : device
      );
      
      return {
        ...state,
        devices: {
          ...state.devices,
          devices: updatedDevices,
        },
      };

    default:
      return state;
  }
}
