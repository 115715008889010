import TagReducer, { TagState } from "./TagReducer";
import { TagAction, setTag } from "./TagAction";
import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer } from "react";

  interface TagContextProps {
    state: TagState;
    dispatch: Dispatch<TagAction>;
  }
  
  const initialState: TagState = {
    tags: {
      tags: [],
      currentPage: 0,
      totalPages: 0,
      previousPage: 0,
      totalItems: 0
    },
  };
  
  export const TagContext = createContext<TagContextProps>({
    state: initialState,
    dispatch: () => {},
  });
  
  interface TagProviderProps {
    children: ReactNode;
  }

  
  export const TagProvider: React.FC<TagProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(TagReducer, initialState);
  
    useEffect(() => {
      setTag(dispatch, 1);
    }, []);
  
    return (
      <TagContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </TagContext.Provider>
    );
  };
  
  export const useTagState = () => {
    const { state, dispatch } = useContext(TagContext);
    return { ...state, dispatch };
  };
  
  