import React, { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import temp from "../../images/temp2.jpeg";
import humidity from "../../images/humidity.jpeg";
import "../../App.css";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import { http } from "../../helper/http";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
// import LineChart from "../../components/apex-chart/LineChart";
import LineChart from "../../components/apex-chart/LineChartDual";
import LongMenu from "../../utils/longMenu/LongMenu";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardTemp2 = ({ selectedDevice, devices }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
//console.log('dashboardSts11',dashboardSts)
  const fetchData = async () => {
    try {
      const response = (await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}`
      )) as any;
      const _data = response.map((d: any) => ({
        timestamp: formatTimestamp(d.timeStamp),
        temperature: d["Temperature"] !== "ERROR" ? d["Temperature"] : "ERROR",
        humidity: d["Humidity"] !== "ERROR" ? d["Humidity"] : "ERROR",
        rssi: d["rssi"],
      })) .filter((d: any) => {
        return !Object.values(d).some(
          (val: any) =>
            typeof val === "string" && val.toLowerCase() === "error"
        );
      });

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  // const tlinechart =
  //   last24HoursData.length > 0
  //     ? last24HoursData.map(({ timestamp, temperature }: any) => ({
  //         timestamp,
  //         value: temperature,
  //       }))
  //     : [];

  // const hlinechart =
  //   last24HoursData.length > 0
  //     ? last24HoursData.map(({ timestamp, humidity }: any) => ({
  //         timestamp,
  //         value: humidity,
  //       }))
  //     : [];

  // const temphum: any = [
  //   {
  //     name: "Temprature",
  //     color: "#eb4034",
  //     data: tlinechart.reverse(),
  //     postfix: " \u2103",
  //   },
  //   {
  //     name: "Humidity",
  //     color: "#5534eb",
  //     data: hlinechart.reverse(),
  //     postfix: " %",
  //   },
  // ];

  const temphum: any = [
    {
      name: "Temperature",
      color: "#eb4034",
      data: [],
      postfix: " \u2103",
    },
    {
      name: "Humidity",
      color: "#5534eb",
      data: [],
      postfix: " %",
    },
  ];

  if (last24HoursData.length > 0) {
    last24HoursData.forEach(({ timestamp, temperature, humidity }: any) => {
      // Temperature data
      temphum[0].data.push({ timestamp, value: temperature });

      // Humidity data
      temphum[1].data.push({ timestamp, value: humidity });
    });

    // Reverse the data arrays
    temphum[0].data.reverse();
    temphum[1].data.reverse();
  }

  const handleExportBarChart = async (exportOption: string) => {
    const data =
      last24HoursData.length > 0
        ? last24HoursData.map(({ timestamp, humidity, temperature }: any) => ({
            TIMESTAMP: timestamp,
            TEMPERATURE: temperature,
            TEMP_UNIT: " °C",
            HUMIDITY: humidity,
            HUM_UNIT: " %",
          }))
        : [];
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(data);
        break;
    
      case "Download as PDF":
        if (data && chartRef) {
          reportDataWithGraph(
            chartRef,
            data,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Quality Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <p className="flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-2 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2">
        <CardTwo
          title="Temperature"
          temperature={`${dashboardSts.temperature + " "}\u2103`}
          icon={temp}
          // background="#ebbcc4"
        />
        <div>
          <CardTwo
            title="Humidity"
            humidity={`${dashboardSts.humidity + " "} %`}
            icon={humidity}
            // background="#d1ffbd"
          />
        </div>
      </div>
      <div
        className="border border-gray-400 rounded-md p-2"
        // style={{ border: "2px solid #EAECF0", padding: "10px" }}
      >
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <p>
          Temperature & Humidity variation with time (between{" "}
          {last24HoursData.length > 0
            ? last24HoursData[last24HoursData.length - 1].timestamp +
              " to " +
              last24HoursData[0].timestamp
            : " "}
          )
        </p>

        <div ref={chartRef}>
          <LineChart
            data={temphum}
            stroke={true}
            xAxisName="Time"
            yAxisName="Temperature ℃ & Humidity %"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardTemp2;
