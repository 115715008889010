import { useEffect, useRef, useState } from "react";
import DynamicCard from "../../components/waterDashboardComponents/DynamicCard";
import axios from "axios";
import {
  filterDataFromEachHourLast24HoursTotalizer,
  formatTimestamp,
  generateAndDownloadPDF,
  generateCSV1,
  processConsumptionDataTotalizer,
  processHourlyDataTotalizer,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import TabularComponents from "../../components/deviceComponents/TabularComponents";
import CustomBarChart from "../../components/dashBoardComponents/BarChart";
import { IconButton } from "@mui/material";
import LongMenu from "../../utils/longMenu/LongMenu";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ImportExport } from "@mui/icons-material";

const baseUrl = process.env.REACT_APP_BASE_URL;

const column = ["TIMESTAMP", "TOTALIZER",  "UNIT"];
const DashboardTotalizer = ({ selectedDevice, devices }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");

    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const _data = response.data.map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          unit: "m³",
          totalizer: d["Totalizer"] || 0,
        };
      }) .filter((d: any) => {
        return !Object.values(d).some(
          (val: any) =>
            typeof val === "string" && val.toLowerCase() === "error"
        );
      });

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);
  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HoursTotalizer(apiData);

  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyDataTotalizer(filterLast24hourDataOfEachHOur);
    consumtionData = processConsumptionDataTotalizer(hourData);
  }

  const handleExportToExcel = () => {
    const csvContent = "data:text/csv;charset=utf-8,";
    const header = column.map((col: any) => col.toLowerCase()); // Convert headers to lowercase

    // Get the current timestamp
    const currentTime = new Date().getTime();

    // Filter rows that are within the last 24 hours
    const validRows = apiData.filter((row) => {
      const rowTimestamp = new Date(row.timestamp).getTime();
      const timeDifference = currentTime - rowTimestamp;
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference <= 24;
    });

    const headerCsv = header.join(",");
    const dataRows = validRows
      .map((row: any) => {
        return header
          .map((col: any) => (row && row[col] ? row[col] : ""))
          .join(",");
      })
      .join("\n");

    const csvExport = headerCsv + "\n" + dataRows;
    const encodedUri = encodeURI(csvContent + csvExport);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exported_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportBarChart = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(consumtionData ? consumtionData : []);
        break;
      case "Download as PDF":
        if (chartRef) {
          reportDataWithGraph(
            chartRef,
            consumtionData ? consumtionData : [],
            devices.deviceName,
            devices.Customer.name,
            "Water Consumption Summary",
            "Last 24 Hours | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <div className="flex-1 ml-2">
        <div className="flex">
          <DynamicCard
            cardTitle="Totalizer"
            cardValue={dashboardSts.totalizer || 0}
            textColor="text-blue-400"
            width="70%"
          />
          <DynamicCard unit="m³" textColor="text-blue-400" width="30%" />
        </div>
      </div>
      <div className="flex mt-3" style={{ height: "600px" }}>
        <div
          className="w-1/2  border border-solid border-gray-300 p-2"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <h1 className="relative p-[0.2rem] h-fit w-[15rem]">
              <p>Data Table</p>
              <p className="text-sm pt-[0.2rem] text-gray-600">
                <AccessTimeIcon className="pr-[0.2rem]" />
                Realtime - Last 24 Hour | IST
              </p>
            </h1>
            <div className="absolute top-0 right-0">
              <IconButton onClick={handleExportToExcel}>
                <ImportExport />
              </IconButton>
            </div>
          </div>
          <TabularComponents
            headers={column}
            rows={last24HoursData}
            pagination={true}
            sorting={true}
          />
        </div>
        <div
          className="w-1/2  border border-solid border-gray-300 p-2"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <h1 className="relative p-[0.2rem] h-fit w-[15rem]">
              <p>Consumption History</p>
              <p className="text-sm pt-[0.2rem] text-gray-600">
                <AccessTimeIcon className="pr-[0.2rem]" />
                Realtime - Last 24 Hour | IST
              </p>
            </h1>
            <div className="absolute top-0 right-0">
              <IconButton>
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as XLS",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportBarChart}
                  id={1}
                />
              </IconButton>
            </div>
          </div>
          <div ref={chartRef}>
          <CustomBarChart  data={consumtionData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTotalizer;
