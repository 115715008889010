import React, { forwardRef, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { fillMissingHours } from "../../common-function/CommonFunction";

interface HourData {
  hour: string;
  consumption?: number;
  firstTimeStamp?: string;
  lastTimeStamp?: string;
  firstPositivecumulative?: number;
  lastPositivecumulative?: number;
}

interface BarchartProps {
  data?: HourData[];
  forwardedRef?: React.MutableRefObject<HTMLDivElement | null>;
  barColor?: string;
}

const CustomBarChart = forwardRef(
  (props: BarchartProps, ref: React.Ref<HTMLDivElement>) => {
    const { data = [], forwardedRef, barColor = "#1f79a7" } = props;
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (chartRef.current && forwardedRef) {
        forwardedRef.current = chartRef.current;
        // console.log(forwardedRef.current); // Corrected the typo
      }
    }, [chartRef.current]); // Include chartRef in the dependency array

    if (data.length === 0) {
      return <div className="flex items-center justify-center h-[300px]">No data available in last 24 hour</div>;
    }
    const emptyBar = fillMissingHours(data);
    const seriesData: any[] = emptyBar.map((entry) => ({
      hour: entry.hour,
      value: entry?.consumption ?? 0,
      firstTimeStamp: entry.firstTimeStamp,
      lastTimeStamp: entry.lastTimeStamp,
    }));

    return (
      <div ref={chartRef} className="ml-[-45px] ">
        <ResponsiveContainer width="100%" height={400} >
          <BarChart
            data={seriesData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey="hour"
              tickFormatter={(hour) => `${hour.split(" ")[1]}:00`} // Extracting hour part from the time
            />
            <YAxis />
            <Tooltip
              content={(props: any) => {
                const { payload } = props;
                if (payload && payload[0]) {
                  const dataPoint = payload[0].payload;
                  return (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        padding: "10px",
                      }}
                    >
                      <p>{`Hour: ${dataPoint.hour}:00`}</p>
                      <p>{`Consumption: ${dataPoint.value.toFixed(3)}`}</p>
                      {/* <p>{`firsttimestamp: ${dataPoint.firstTimeStamp}`}</p> */}
                      {/* <p>{`last: ${dataPoint.lastTimeStamp}`}</p> */}
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />
            <Bar dataKey="value" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default CustomBarChart;
