import  { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { JwtPayload, jwtDecode } from "jwt-decode";

interface ProtectedRouteRoleProp {
  roleids?: any
}

const ProtectedRouteRole: React.FC<ProtectedRouteRoleProp> = ({ roleids = [1] }) => {
  const navigate = useNavigate();
  const [token] = useState(window.localStorage.getItem("token"));
  const [allow, setAllow] = useState(false);

  useEffect(() => {
      if(token){
        try {
          const decodedToken: any = jwtDecode<JwtPayload>(token);
          if(roleids.includes(decodedToken.role)){
            setAllow(true)
          } else {
            navigate('/');
          }
        } catch (error) {
          console.log("Error decoding token: ", error)
        }
    }
  }, [roleids]);
  return <>{allow ? <Outlet /> : ""}</>;
};

export default ProtectedRouteRole;





