import React from 'react';
import './Battery.css';

interface BatteryProps {
    level: number; // Battery level should be between 0 and 100
}

const Battery: React.FC<BatteryProps> = ({ level }) => {
    const batteryLevel = Math.max(0, Math.min(level, 100)); // Ensure level is between 0 and 100
    const numberOfBars = 10; // Number of bars in the battery
    const filledBars = Math.round((batteryLevel / 100) * numberOfBars);


    let barColorClass = 'filled'; // Default color (green)
    if (batteryLevel < 25) {
        barColorClass = 'low';
    } else if (batteryLevel < 50) {
        barColorClass = 'medium';
    } else if (batteryLevel < 75) {
        barColorClass = 'high';
    } else if (batteryLevel > 75) {
        barColorClass = 'filled';
    }


    return (
        <div className="battery">
            <div className="battery-body">
                {Array.from({ length: numberOfBars }, (_, i) => (
                    <div
                        key={i}
                        className={`battery-bar ${i < filledBars ? barColorClass : ''}`}
                    ></div>
                ))}
            </div>
            <div className="battery-cap"></div>
            <span className="battery-percentage">{batteryLevel}%</span>
        </div>
    );
};

export default Battery;
