

import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from "dayjs";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { filterDataFromEachHourLast24Hours, processConsumptionData, processHourlyData } from "./utils/util";
import { formatDate, formatTimestamp, generateCSV1, reportDataWithGraph } from "../../../common-function/CommonFunction";
import { http } from "../../../helper/http";
import DynamicCard from "../../../components/waterDashboardComponents/DynamicCard";
import SignalStrength from "../../../components/waterDashboardComponents/SignalStrength";
import { Dialog, IconButton, TextField } from "@mui/material";
import CheckBoxFilter from "../../../components/CheckboxField";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import LongMenu from "../../../utils/longMenu/LongMenu";
import LineChart3 from "../../../components/dashBoardComponents/stepAfterchart3";
import CustomBarChart from "../../../components/dashBoardComponents/BarChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MotorFlowChart from "../MotorFlowChart";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const headers = [
  "TIMESTAMP",
  "FLOW",
  "FLOWUNIT",
  "TOTAL",
  "TOTALUNIT"
];

interface FormData {
  borewellId: string;
  meterSerialNo: string;
  lastCalibrationDate: Dayjs | null;
  dateOfInstallation: Dayjs | null;
  emailId: string;
}
const baseUrl = process.env.REACT_APP_BASE_URL;
const M20tesxt = ({ selectedDevice, d }: any) => {
  const [filterTable, setFilterTable] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);

  const socketInitData: any = {
    timestamp: "N/A",
    flow: "N/A",
    flowunit: "",
    total: "N/A",
    totalunit: "",
    rssi: 0,
  };
  const [dashboardSocketData, setDashboardSocketData] =
    useState(socketInitData);
  const [apiData, setApiData] = useState<Row[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    borewellId: '',
    meterSerialNo: '',
    lastCalibrationDate: null,
    dateOfInstallation: null,
    emailId: '',
  });

  const { devices } = useDeviceState();
  const chartRef1 = useRef<HTMLDivElement>(null);

  let device: any;
  if (devices.devices.length > 0) {
    device = devices.devices.find((device: { id: any; }) => device.id === selectedDevice);
  }

  interface Row {
    timestamp: string;
    flow: string;
    flowunit: string;
    positivecumulative: string;
    negativecumulative: string;
    total: string;
    totalunit: string;
    [key: string]: string;
  }

  const handleSelectedDataChange = (selectedData: string[]) => {
    setSelectedHeaders(selectedData);
  };


  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24Hours(apiData);
  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyData(filterLast24hourDataOfEachHOur);
    consumtionData = processConsumptionData(hourData);

  }

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl +
        `/device-data-log?deviceId=${selectedDevice}&last24=24`
      );

   
      const _data = response
      .map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          rssi: d["rssi"],
          flow: parseFloat(d["Flow"]),
          flowunit: "m³/hr",
          total: d["Total"],
          totalunit: "m³",
        };
      })
      .filter((d: any) => {
        return !Object.values(d).some((val: any) => {
          return typeof val === "string" && val.toLowerCase() === "error";
        });
      });
    
          
          
  


      setApiData(_data);
      setDashboardSocketData(_data.length ? _data[0] : socketInitData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData()
  }, [selectedDevice]);

  function getSanitizedValue(value: any) {
    if (typeof value === "number") {
      return value.toFixed(3);
    } else {
      return value;
    }
  }








  const chartRef = useRef<HTMLDivElement>(null);
  const handleExportBarChart = async (exportOption: string) => {
    const data: any = consumtionData?.map(({ hour, firstTimeStamp, lastTimeStamp, consumption }) => {
      return {
        Hour: hour,
        "First Timestamp": firstTimeStamp,
        "Last Timestamp": lastTimeStamp,
        Consumption: consumption
      }
    })
    if (data?.length === 0) return
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(data)
        break;
      case "Download as PDF":
        if (apiData && chartRef1) {
          reportDataWithGraph(
            chartRef,
            data,
            d?.deviceName,
            d?.Customer?.name,
            "Water Quality Summary",
            "Realtime - Last 24 Hour | IST"
          );
        }
        return;
      default:
        return;
    }
  };



  const handleExportLineChart = async (exportOption: string) => {
    const data = apiData.map(({ timestamp, flow, flowunit }) => {
      return {
        "Time Stamp": timestamp,
        "Flow": flow,
        "Flow UNit": flowunit
      }
    })
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(data)
        break;
      case "Download as PDF":
        if (apiData && chartRef1) {
          reportDataWithGraph(
            chartRef1,
            data,
            d?.deviceName,
            d?.Customer?.name,
            "Water Quality Summary",
            "Realtime - Last 24 Hour | IST"
          );
        }
        return;
      default:
        return;
    }
  };

  const handleExportToExcel = () => {
    const data = apiData.map(({ timestamp, flow, flowunit, total, totalunit }) => {
      return {
        "Time Stamp": timestamp,
        "Flow": flow,
        "Flow UNit": flowunit,
        "Total": total,
        "Total Unit": totalunit
      }
    })
    generateCSV1(data)
  };



  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleDateChange = (name: 'lastCalibrationDate' | 'dateOfInstallation') => (date: Dayjs | null) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: date }));
  };

  const handleSaveClick = () => {
    const formattedData = {
      ...formData,
      lastCalibrationDate: formData.lastCalibrationDate?.format('YYYY-MM-DD') || null,
      dateOfInstallation: formData.dateOfInstallation?.format('YYYY-MM-DD') || null,
    };
    localStorage.setItem('installationDetails', JSON.stringify(formattedData));
    setShowPopup(false);
  };

  const handleIconClick = () => {
    // Load existing data from localStorage when opening the popup
    const existingData = JSON.parse(localStorage.getItem('installationDetails') || '{}');
    setFormData({
      borewellId: existingData.borewellId || '',
      meterSerialNo: existingData.meterSerialNo || '',
      lastCalibrationDate: existingData.lastCalibrationDate ? dayjs(existingData.lastCalibrationDate) : null,
      dateOfInstallation: existingData.dateOfInstallation ? dayjs(existingData.dateOfInstallation) : null,
      emailId: existingData.emailId || '',
    });
    setShowPopup(true);
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };

  const installationDetails = JSON.parse(localStorage.getItem('installationDetails') ?? '{}');

  return (
    <>
      <div className="flex flex-col md:flex-row ">
        <div className="w-full md:w-1/2 h-full p-2">
          <div className="h-1/2 overflow-auto flex border border-solid border-gray-300 rounded p-4">
            <div className="w-auto md:w-1/5 h-[302px] ">
              <DynamicCard
                customComponent={
                  <SignalStrength percentage={dashboardSocketData.rssi} />
                }
                width="100%"
                height="100%"
              />
            </div>
            <div className="flex-1 ml-2">
              <div className="flex">
                <DynamicCard
                  cardTitle="Flow"
                  cardValue={getSanitizedValue(dashboardSocketData.flow) as any}
                  textColor="text-blue-400"
                  width="70%"
                />
                <DynamicCard
                  unit="m³/hr"
                  textColor="text-blue-400"
                  width="30%"
                />
              </div>
              <div className="flex">
                <DynamicCard
                  cardTitle="Total"
                  cardValue={
                    getSanitizedValue(
                      dashboardSocketData.total
                    ) as any
                  }
                  textColor="text-purple-400"
                  width="70%"
                />
                <DynamicCard
                  unit="m³"
                  textColor="text-purple-400"
                  width="30%"
                />
              </div>
            </div>
          </div>
          <div className="h-[705px] overflow-auto shadow-xl mt-4 z-11 border border-solid border-gray-300 rounded px-4">
            <div className="flex items-center sticky top-0 bg-white py-1" >
              <p className="font-semibold text-lg text-slate-600">Last 24 Hours Meta Data Reading</p>
              <div className="ml-auto mt-4 xl:mt-0 ">

                <Dialog
                  open={filterTable}
                  onClose={() => {
                    setFilterTable(false);
                  }}
                >

                  <CheckBoxFilter
                    data={headers}
                    onSelectedDataChange={handleSelectedDataChange}
                  />
                </Dialog>
                <IconButton onClick={handleExportToExcel}>
                  <ArrowCircleDownIcon />
                </IconButton>

              </div>
            </div>
            <TabularComponents
              headers={apiData.length > 0 ? selectedHeaders : headers}
              rows={apiData.reverse()}
              sortableColumns={["TIMESTAMP"]}
            />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2">

          <div
            ref={chartRef1}
            className=" overflow-auto shadow-xl flex flex-col 
            items-center justify-center border border-solid border-gray-300 rounded px-2
             h-[380px]"
            style={{ boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="flex relative  items-center justify-between w-full">
              <h1 className="relative px-2 h-fit w-[15rem] ">

                <p className="text-sm mt-6 text-gray-600">
                  <AccessTimeIcon className="pr-[0.2rem]" />
                  Realtime - Last 24 Hour Flow
                </p>
              </h1>
              <div className="mt-4">
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportLineChart}
                  id={1}
                />
              </div>
            </div>
            <LineChart3
              height="200"
              apiData={apiData}
            />
          </div>

          <div
            className="overflow-hidden mt-2 border border-solid border-gray-300 rounded p-4"
            style={{
              boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)",
              maxHeight: "380px",
              minHeight: "380px",
            }}
          >
            <div className="flex relative  items-center justify-between w-full">
              <h1 className="relative px-2 h-fit w-full ">

                <p className="text-sm mt-6 text-gray-600">
                  <AccessTimeIcon className="pr-[0.2rem]" />
                  Realtime - Last 24 Hour Consumption Summary
                </p>
              </h1>
              <div className="mt-4">
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportBarChart}
                  id={1}
                />
              </div>
            </div>

            <div ref={chartRef} className="h-[400px]">
              <CustomBarChart data={consumtionData} />
            </div>
          </div>

          <div className="grid md:grid-cols-2 mt-2 gap-4">

            <div className="bg-white shadow-md py-4 border border-solid border-gray-300">

              <div className="px-4 text-[#333333]">
                <div className="flex">
                  <div className="w-[90%] h-auto">
                    <h2 className="text-lg font-bold">Installation Details</h2>
                  </div>
                  <div className="cursor-pointer" onClick={handleIconClick}>
                    <EditIcon />
                  </div>


                  {showPopup && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <div className="bg-white p-6 rounded shadow-lg relative">
                        <div>
                          <div className="flex">
                            <div className="w-[96%]">
                              <p className="text-lg font-sans-serif text-[20px]">Edit onboarding form </p>
                            </div>
                            <div className="flex justify-end">
                              <button
                                className="text-gray-500 hover:text-gray-800"
                                onClick={handleCloseClick}
                              >
                                <CloseIcon />
                              </button>
                            </div>
                          </div>

                          <div className="mt-3">
                            <TextField
                              name="borewellId"
                              label="Borewell ID"
                              value={formData.borewellId}
                              onChange={handleChange}
                              className="mt-2 w-full"
                            />

                            <div className="flex gap-4 mt-2">
                              <TextField
                                name="meterSerialNo"
                                label="Meter Serial No"
                                value={formData.meterSerialNo}
                                onChange={handleChange}
                                className="mt-2 w-full"
                              />
                            </div>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <div className="flex gap-4 mt-2">
                                <DatePicker
                                  label="Last Calibration Date"
                                  value={formData.lastCalibrationDate}
                                  onChange={handleDateChange('lastCalibrationDate')}
                                />
                                <DatePicker
                                  label="Date Of Installation"
                                  value={formData.dateOfInstallation}
                                  onChange={handleDateChange('dateOfInstallation')}
                                />
                              </div>
                            </LocalizationProvider>

                            <div className="mt-2">
                              <TextField
                                name="emailId"
                                label="Email ID"
                                value={formData.emailId}
                                onChange={handleChange}
                                className="mt-2 w-full"
                              />
                            </div>

                            <div className="mt-14 flex justify-end">
                              <button
                                className="border py-2 px-4 rounded-md font-sans-serif text-[14px]"
                                onClick={handleCloseClick}
                              >
                                Cancel
                              </button>
                              <button
                                className="border py-2 px-6 rounded-md ml-4 font-sans-serif text-[14px]"
                                onClick={handleSaveClick}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex mt-1 ">
                  <span className="block text-xs font-medium leading-8">BOREWELL ID:</span>
                  <span className="block text-xs font-medium leading-8 ml-auto">{installationDetails?.borewellId}</span>
                </div>


                <div className="flex">
                  <span className="block text-xs font-medium leading-8">METER SERIAL NO:</span>
                  <span className="block text-xs font-medium leading-8 ml-auto">{installationDetails?.meterSerialNo}</span>
                </div>

                <div className="flex">
                  <span className="block text-xs font-medium leading-8">LAST CALIBRATION DATE:</span>
                  <span className="block text-xs font-medium leading-8 ml-auto">{installationDetails?.lastCalibrationDate}</span>
                </div>

                <div className="border w-full"></div>

                <div className="flex">
                  <span className="block text-xs font-medium leading-8">DATE OF INSTALLATION:</span>
                  <span className="block text-xs font-medium leading-8 ml-auto">{installationDetails?.dateOfInstallation}</span>
                </div>

                <div className="flex">
                  <span className="block text-xs font-medium leading-8">EMAIL ID:</span>
                  <span className="block text-xs font-medium leading-8 ml-auto">{installationDetails?.emailId}</span>
                </div>

              </div>


            </div>


            <div className="p-4 bg-white shadow-md border border-solid border-gray-300">
              <h2 className="text-lg font-bold">Motor Running Status</h2>
              <div className="ml-[-20px]">
                <MotorFlowChart apiData={apiData} />
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default M20tesxt;

