import { Customer, PaginatedCustomers } from "../../types/CustomerTypes";
import { http } from "../../helper/http";

interface SetCustomerAction {
  type: "SET_CUSTOMERS";
  payload: PaginatedCustomers;
}

interface SetCustomerPageAction {
  type: "SET_CUSTOMERS_WITH_PAGE";
  payload: PaginatedCustomers;
}


interface AddCustomersAction {
  type: "ADD_CUSTOMERS";
  payload: { customers: Customer, totalItems: number };
}

interface UpdateCustomerAction {
  type: "UPDATE_CUSTOMERS";
  payload: { id: number; customers: Customer };
}

interface UpdateCustomersStatusAction {
  type: "UPDATE_CUSTOMERS_STATUS";
  payload: { id: number; status: boolean };
}

interface SetAllCustomerAction {
  type: "SET_CUSTOMERS_ALL";
  payload: Customer[];
}



export type CustomerAction =
  | SetCustomerAction
  | SetCustomerPageAction
  | AddCustomersAction
  | UpdateCustomerAction
  | UpdateCustomersStatusAction
  | SetAllCustomerAction;

interface ApiResponse {
  message: string;
  customers: PaginatedCustomers;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setCustomer(dispatch: React.Dispatch<CustomerAction>, payload: number) {
  try {
    const page = payload ?? 1;
    const response = (await http(baseUrl + `/customer?page=${page}`)) as ApiResponse;
    const responseData = response;
    dispatch({
      type: "SET_CUSTOMERS",
      payload: responseData.customers,
    });
  } catch (err) {
    console.error(err);
  }
}

export function getCustomerPageClick(
  dispatch: React.Dispatch<CustomerAction>,
  payload: any
) {
  dispatch({
    type: "SET_CUSTOMERS_WITH_PAGE",
    payload,
  });
}



export function addNewCustomers(
  dispatch: React.Dispatch<CustomerAction>,
  payload: { customers: Customer, totalItems: number }
) {
  dispatch({
    type: "ADD_CUSTOMERS",
    payload,
  });
}

export function updateCustomer(
  dispatch: React.Dispatch<CustomerAction>,
  payload: { id: number; customers: Customer }
) {
  dispatch({
    type: "UPDATE_CUSTOMERS",
    payload,
  });
}

export function updateCustomerStatus(
  dispatch: React.Dispatch<CustomerAction>,
  payload: { id: number; status: boolean }
) {
  dispatch({
    type: "UPDATE_CUSTOMERS_STATUS",
    payload,
  });
}

export function setAllCustomer(
  dispatch: React.Dispatch<CustomerAction>,
  payload: Customer[]
) {
  dispatch({
    type: "SET_CUSTOMERS_ALL",
    payload,
  });
}
