import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import UserProfile from '../../components/navBarComponents/UserProfile';







const Navbar2 = (props: {
  
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <header className="sticky top-0 z-40 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          {/* <!-- Hamburger Toggle BTN --> */}
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
          >
            <MenuIcon/>
          </button>
          {/* <!-- Hamburger Toggle BTN --> */}

          <Link className="block flex-shrink-0" to="/">
            {/* <img src={darkMode? Logo2 : Logo1} alt="Logo" style={{width:'7%',borderRadius: '25%'}} /> */}
          </Link>
        </div>

        <div className="hidden sm:block">
            <div className="relative flex items-center">

            </div>
        </div>
        <div className="flex items-center gap-3 2xsm:gap-7 z-20">
          <ul className="flex items-center gap-2 2xsm:gap-4">
            {/* <!-- Dark Mode Toggler --> */}

            {/* <!-- Notification Menu Area --> */}
            {/* <NotificationBar/> */}

          </ul>

          {/* UserProfile Section */}
          <UserProfile/>

        </div>

      </div>
    </header>
  );
};

export default Navbar2;
