import { PaginatedCustomers, Customer } from "../../types/CustomerTypes";

export interface CustomerState {
  customers: PaginatedCustomers;
}

export type CustomerAction =
  | { type: "SET_CUSTOMERS"; payload: PaginatedCustomers }
  | { type: "SET_CUSTOMERS_ALL"; payload: Customer[] }
  | { type: "SET_CUSTOMERS_WITH_PAGE"; payload: PaginatedCustomers }
  | { type: "ADD_CUSTOMERS"; payload: { customers: Customer, totalItems: number } }
  | { type: "UPDATE_CUSTOMERS"; payload: { id: number | undefined; customers: Customer } }
  | {
    type: "UPDATE_CUSTOMERS_STATUS";
    payload: { id: number; status: boolean };
  };

export default function CustomerReducer(
  state: CustomerState,
  action: CustomerAction
): CustomerState {
  const { type, payload } = action;

  switch (type) {
    case "SET_CUSTOMERS":
      return { ...state, customers: payload };
    case "SET_CUSTOMERS_ALL":
      return {
        ...state,
        customers: {
          customers: action.payload,
          currentPage: 1, // Assuming some default values for pagination
          totalPages: 1,
          totalItems: action.payload.length
        }
      };
    case "SET_CUSTOMERS_WITH_PAGE":
      return { ...state, customers: payload };
    case "ADD_CUSTOMERS":
      const { customers: newCustomer, totalItems } = payload;
      return {
        ...state,
        customers: {
          ...state.customers,
          customers: [newCustomer, ...state.customers.customers],
          totalItems: totalItems + 1
        },
      };
    case "UPDATE_CUSTOMERS":
      const { id: updateId, customers } = payload;
      const updatedCustomer = state.customers.customers.map((customer) =>
        customer.id === updateId ? { ...customer, ...customers } : customer
      );
      return {
        ...state,
        customers: {
          ...state.customers,
          customers: updatedCustomer,
        },
      };
    case "UPDATE_CUSTOMERS_STATUS":
      const { id, status } = payload;
      const customerToUpdate = state.customers.customers.find(
        (customer) => customer.id === id
      );
      if (customerToUpdate) {
        // Update the status of the found device
        customerToUpdate.status = status;
        // Clone the devices array to trigger a state update
        const updatedDevicesAfterStatusUpdate = [...state.customers.customers];
        // Update the state with the new devices array
        return {
          ...state,
          customers: {
            ...state.customers,
            customers: updatedDevicesAfterStatusUpdate,
          },
        };
      }
      return state;
    default:
      return state;
  }
}
