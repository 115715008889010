import { PaginatedTemplate, Template } from "../../types/TemplateType";
import { TemplateAction } from "./TemplateAction";

export interface TemplateState {
  templates: PaginatedTemplate;
}

export type CustomerAction =
  | { type: "SET_TEMPLATES"; payload: PaginatedTemplate }
  | { type: "SET_TEMPLATE_WITH_PAGE"; payload: PaginatedTemplate }
  | { type: "SET_TEMPLATE_ALL"; payload: Template[] }
  | { type: "ADD_TEMPLATES"; payload: { templates: Template, totalItems: number } }
  | { type: "UPDATE_TEMPLATES"; payload: { id: number | undefined; templates: Template } }
//   | {
//       type: "UPDATE_CUSTOMERS_STATUS";
//       payload: { id: number; status: boolean };
//     };

export default function TemplateReducer(
  state: TemplateState,
  action: TemplateAction
): TemplateState {
  const { type, payload } = action;

  switch (type) {
    case "SET_TEMPLATES":
      return { ...state, templates: payload };
    case "SET_TEMPLATE_WITH_PAGE":
      return { ...state, templates: payload }
    case "SET_TEMPLATE_ALL":
      return {
        ...state,
        templates: {
          templates: action.payload,
          currentPage: 1, // Assuming some default values for pagination
          totalPages: 1,
          totalItems: action.payload.length
        }
      };
    case "ADD_TEMPLATES":
      const { templates: newTemplate, totalItems } = payload;
      return {
        ...state,
        templates: {
          ...state.templates,
          templates: [newTemplate, ...state.templates.templates],
          totalItems: totalItems + 1
        },
      };
    case "UPDATE_TEMPLATES":
      const { id: updateId, templates } = payload;
      const updatedCustomer = state.templates.templates.map((template) =>
        template.id === updateId ? { ...template, ...templates } : template
      );
      return {
        ...state,
        templates: {
          ...state.templates,
          templates: updatedCustomer,
        },
      };
    default:
      return state;
  }
}
