import { http } from "../../helper/http";
import { PaginatedTemplate, Template } from "../../types/TemplateType";

interface SetTemplateAction {
  type: "SET_TEMPLATES";
  payload: PaginatedTemplate;
}

interface SetTemplatePageAction {
  type: "SET_TEMPLATE_WITH_PAGE";
  payload: PaginatedTemplate;
}


interface AddTemplateAction {
  type: "ADD_TEMPLATES";
  payload: { templates: Template, totalItems: number };
}

interface UpdateTemplateAction {
  type: "UPDATE_TEMPLATES";
  payload: { id: number; templates: Template };
}

interface SetAllTemplateAction {
  type: "SET_TEMPLATE_ALL";
  payload: Template[];
}


// interface UpdateCustomersStatusAction {
//   type: "UPDATE_CUSTOMERS_STATUS";
//   payload: { id: number; status: boolean };
// }



export type TemplateAction =
  | SetTemplateAction
  | AddTemplateAction
  | SetTemplatePageAction
  | UpdateTemplateAction
  | SetAllTemplateAction


interface ApiResponse {
  message: string;
  templates: PaginatedTemplate;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setTemplate(dispatch: React.Dispatch<TemplateAction>, payload: number) {
  try {
    const page = payload ?? 1;
    const response = (await http(baseUrl + `/templates?page=${page}`)) as ApiResponse;
    const responseData = response;
    
    

    dispatch({
      type: "SET_TEMPLATES",
      payload: responseData.templates,
    });
  } catch (err) {
    console.error(err);
  }
}

export function getTemplatePageClick(
  dispatch: React.Dispatch<TemplateAction>,
  payload: PaginatedTemplate
) {
  dispatch({
    type: "SET_TEMPLATE_WITH_PAGE",
    payload,
  });
}



export function addNewTemplates(
  dispatch: React.Dispatch<TemplateAction>,
  payload: { templates: Template, totalItems: number }
) {
  dispatch({
    type: "ADD_TEMPLATES",
    payload,
  });
}

export function updateTemplate(
  dispatch: React.Dispatch<TemplateAction>,
  payload: { id: number; templates: Template }
) {
  dispatch({
    type: "UPDATE_TEMPLATES",
    payload,
  });
}

// export function updateCustomerStatus(
//   dispatch: React.Dispatch<CustomerAction>,
//   payload: { id: number; status: boolean }
// ) {
//   dispatch({
//     type: "UPDATE_CUSTOMERS_STATUS",
//     payload,
//   });
// }


export function setAllTemplate(
  dispatch: React.Dispatch<TemplateAction>,
  payload: Template[]
) {
  dispatch({
    type: "SET_TEMPLATE_ALL",
    payload,
  });
}
