// // import{useEffect, useRef, useState} from "react";
// // import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// // import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// // import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// // import { useParams } from "react-router-dom";
// // import { http } from "../../helper/http";
// // import { useUserState } from "../../app-context/auth-context/AuthState";
// // import { toast } from "react-toastify";
// // import dayjs, { Dayjs } from "dayjs";
// // import 'jspdf-autotable';
// // import { DatePicker } from '@mui/x-date-pickers';

// // import 'jspdf-autotable';
// // import utc from 'dayjs/plugin/utc';
// // import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// // import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// // import {
// //   downloadExcelReportForMeterReadin,

// //   downloadPDFMetadata,

// // } from "../../common-function/Reportgenerate";
// // dayjs.extend(utc);
// // dayjs.extend(isSameOrAfter);
// // dayjs.extend(isSameOrBefore);
// // const baseUrl = process.env.REACT_APP_BASE_URL;




// // const MeterReadingData = () => {
// //   const [startDate, setStartDate] = useState<any>(null);
// //   const [endDate, setEndDate] = useState<any>(null);
// //   const [apiData, setApiData] = useState<any>(null);
// // console.log('apiData',apiData)
// //   const [reportType, setReportType] = useState("meterReadingData");
// //   const [reportFormat, setReportFormat] = useState("excel");
// //   const [method, setMethod] = useState("manual");
// //   const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
// //   const { customerId } = useParams();
// //   const { users } = useUserState();
// //   const [selectedSensor, setSelectedSensor] = useState('');
// //   const [reportPeriodicity, setReportPeriodicity] = useState('Custom');
// //   const [devices, setDevices] = useState<any[]>([]);
// //   const [searchTerm, setSearchTerm] = useState('');
// //   const [filteredDevices, setFilteredDevices] = useState<string[]>([]);

// //   useEffect(() => {
// //     const filtered = devices.filter(device => 
// //       device.toLowerCase().includes(searchTerm.toLowerCase())
// //     );
// //     setFilteredDevices(filtered);
// //   }, [searchTerm, devices]);


// //  useEffect(() => {
// //   const fetchDataOverview = async () => {
// //     try {
// //       // First API call
// //       const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
// //       const devices1 = response1.devices || [];
// //       const deviceNames1 = devices1.map((device: any) => device.deviceName).filter(Boolean);

// //       // Second API call
// //       const response2 = (await http(`${baseUrl}/assign-device?userId=${users.user.id}`)) as any;
      
// //       // Check if response2.devices exists and is an array
// //       const devices2 = Array.isArray(response2.devices) ? response2.devices : 
// //                        (Array.isArray(response2.devices?.devices) ? response2.devices.devices : []);

// //       const deviceNames2 = devices2.map((device: any) => {
// //         return device.Device?.deviceName || device.deviceName;
// //       }).filter(Boolean);

   

// //       // Combine device names from both APIs
// //       const allDeviceNames = [...deviceNames1, ...deviceNames2];

// //       // Get unique device names
// //       const uniqueDeviceNames = Array.from(new Set(allDeviceNames));

// //       // Set the unique device names
// //       setDevices(uniqueDeviceNames);
   
// //     } catch (error) {
// //       console.error('Error fetching data:', error);
// //     }
// //   };

// //   fetchDataOverview();
// // }, [users.user.id]);


// //   function calculateFlow(_data: any, i: any) {
// //     if (i === _data.length - 1) {
// //       return getSanitizedValue(0);
// //     }
// //     const currentData = _data[i];
// //     const previousData = _data[i + 1];

// //     if (!previousData) {
// //       return getSanitizedValue(0); // or some other default value
// //     }

// //     if (
// //       currentData["Differential Total Flow"] === "ERROR" ||
// //       previousData["Differential Total Flow"] === "ERROR"
// //     ) {
// //       return getSanitizedValue(0);
// //     }

// //     const currentFlow =
// //       currentData["Differential Total Flow"] !== "ERROR"
// //         ? +currentData["Differential Total Flow"]
// //         : 0;
// //     const previousFlow =
// //       previousData["Differential Total Flow"] !== "ERROR"
// //         ? +previousData["Differential Total Flow"]
// //         : 0;
// //     const timeDiff =
// //       +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
// //     let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
// //     if (flow < 0) {
// //       flow = 0;
// //     }
// //     const v = getSanitizedValue(flow * 0.001);
// //     if (isNaN(v)) {
// //       return 0;
// //     }

// //     return v;
// //   }

// //   function getSanitizedValue(value: any) {
// //     if (typeof value === "number") {
// //       return value.toFixed(3);
// //     } else {
// //       return value;
// //     }
// //   }

// //   const dropdownRef = useRef<HTMLDivElement>(null);
// //   useEffect(() => {
// //     function handleClickOutside(event: MouseEvent) {
// //       if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
// //         setShowDevices(false);
// //       }
// //     }

// //     document.addEventListener("mousedown", handleClickOutside);
// //     return () => {
// //       document.removeEventListener("mousedown", handleClickOutside);
// //     };
// //   }, []);

// //   const fetchData = async (startD: Date, endD: Date) => {
// //   try {
// //     const response = await http(
// //       `${baseUrl}/report?startDate=${startD.toISOString()}&endDate=${endD.toISOString()}&customerId=${customerId}`
// //     ) as {
// //       deviceName: string;
// //       data: { status: number; data: any[] };
// //       template: number;
// //     }[];
// // console.log('response',response)
// //     setApiData(response);

// //     let _data: any[] = [];

// //     for (const obj of response) {


// //       if (Array.isArray(obj.data.data)) {
// //         if (obj.template === 5) {
// //           if (reportType === "meterReadingData") {
// //             const processedData = obj.data.data.map((d: any, i: number) => {
// //               return {
// //                 logId: d.logId,
// //                 timestamp: formatTimestamp(d.timeStamp),
// //                 flow: calculateFlow(obj.data.data, i),
// //                 flowunit: "m³/hr",
// //                 positivecumulative:
// //                   d["Forward Total Flow"] !== "ERROR"
// //                     ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
// //                     : "ERROR",
// //                 negativecumulative:
// //                   d["Reverse Total Flow"] !== "ERROR"
// //                     ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
// //                     : "ERROR",
// //                 total:
// //                   d["Differential Total Flow"] !== "ERROR"
// //                     ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
// //                     : "ERROR",
// //                 totalunit: "m³",
// //                 rssi: d.rssi,
// //               };
// //             });
// //             _data.push(...processedData);
// //           } 
          
// //         } else if (obj.template === 6) {
// //           // process data for template 6
// //         } else if (obj.template === 7) {
// //           // process data for template 7
// //         }
// //       } else {
// //         console.error("obj.data.data is not an array", obj);
// //       }
// //     }

// //   } catch (error) {
// //     console.error(error);
// //     toast.error("Failed to fetch data");
// //   }
// //   };

// //   function formatTimestamp(timestamp: string) {
// //     return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
// //   }

// //   useEffect(() => {
// //     if (startDate && endDate) {
// //       fetchData(startDate.toDate(), endDate.toDate());
// //     }
// //   }, [startDate, endDate]);


  
// //   const [showDevices, setShowDevices] = useState(false);

// //   const handleToggleDevices = () => {
// //     setShowDevices(!showDevices);
// //   };
  
// //   const handleDeviceSelection = (device: string) => {
// //     if (selectedDevices.includes(device)) {
// //       setSelectedDevices(selectedDevices.filter((d) => d !== device));
// //     } else {
// //       setSelectedDevices([...selectedDevices, device]);
// //     }
// //   };
// //   const [selectAllDevices, setSelectAllDevices] = useState(false);

// //   const handleSelectAllDevices = (checked: boolean) => {
// //     if (checked) {
// //       setSelectedDevices(devices);
// //     } else {
// //       setSelectedDevices([]);
// //     }
// //     setSelectAllDevices(checked);
// //   };
  
// //   const handleReset = () => {
// //     setStartDate(null);
// //     setEndDate(null);
// //     setApiData(null);
// //     setReportType("meterReadingData");
// //     setReportFormat("excel");
// //     setMethod("manual");
// //     setSelectedDevices([]);
// //   };

// //   const handleClick = () => {
// //     if (selectedDevices.length === 0) {
// //       toast.error("Please select at least one device");
// //       return;
// //     }
  
   
// //       if (reportFormat === "excel") {
// //         downloadExcelReportForMeterReadin(selectedDevices, apiData, startDate, endDate, users);
// //       } else if (reportFormat === "PDF") {
// //         downloadPDFMetadata(selectedDevices, apiData, startDate, endDate, users);
// //       }
   
  
// //   };


// //     return (
// //     <>
     
    

// //       <div className="px-4 ">
// //         <div className="border w-[562px] px-2 py-4 rounded-md">
         
// //           <div className="mx-2 mt-2">

// //              <div className="grid grid-cols-2 justify-items-start mt-4 gap-4">
// //               <div className="w-full">
// //                 <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// //                   Select Report Format
// //                 </p>
// //                 <div className="mt-2">
// //                   <select
// //                     className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
// //                     value={reportFormat}
// //                     onChange={(e) => setReportFormat(e.target.value)}
// //                   >
// //                     <option value="selectReportFormat">
// //                       Select Report Format
// //                     </option>
// //                     <option value="excel">Excel</option>
// //                     <option value="PDF">PDF</option>
// //                   </select>
// //                 </div>
// //               </div>
// //               <div className="w-full">
// //                 <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// //                   Method
// //                 </p>
// //                 <div className="mt-2">
// //                   <select
// //                     className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
// //                     value={method}
// //                     onChange={(e) => setMethod(e.target.value)}
// //                   >
// //                     <option value="manual">Manual</option>
// //                   </select>
// //                 </div>
// //               </div>
// //              </div>

// //              <div className="mt-4">
// //               <LocalizationProvider dateAdapter={AdapterDayjs}>
// //                 <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
// //                   <div className="w-full">
// //                     <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// //                       Start Date
// //                     </p>
// //                     <DateTimePicker
// //                       value={startDate}
// //                       onChange={(e: any) => setStartDate(e)}
// //                       className="w-[100%] bg-[#F8FAFC] "
// //                     />
// //                   </div>
// //                   <div className="w-full">
// //                     <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// //                       End Date
// //                     </p>
// //                     <DateTimePicker
// //                       value={endDate}
// //                       onChange={(e: any) => setEndDate(e)}
// //                       className="w-[100%] bg-[#F8FAFC] mt-2"
// //                     />
// //                   </div>
// //                 </div>
// //               </LocalizationProvider>
// //              </div>

// //              <div className="w-full mt-4" ref={dropdownRef}>
// //               <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// //                 Select Devices
// //               </p>
// //               <div className="mt-2">
// //                 <div className="relative">
// //                   <button
// //                     className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 font-semibold py-4 px-4 w-full text-left"
// //                     onClick={handleToggleDevices}
// //                   >
// //                     {selectedDevices.length > 0
// //                       ? selectedDevices.join(", ")
// //                       : "Select Devices"}
// //                   </button>
// //                   {showDevices && (
// //                     <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
// //                       <div className="sticky top-0 bg-white p-2">
// //                         <input
// //                           type="text"
// //                           placeholder="Search devices..."
// //                           value={searchTerm}
// //                           onChange={(e) => setSearchTerm(e.target.value)}
// //                           className="w-full p-2 border rounded"
// //                         />
// //                       </div>
// //                       <ul>
// //                         <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
// //                           <input
// //                             type="checkbox"
// //                             checked={selectAllDevices}
// //                             onChange={(e) => handleSelectAllDevices(e.target.checked)}
// //                           />
// //                           <span className="ml-2">Select All Devices</span>
// //                         </li>
// //                         {filteredDevices.map((deviceName: string, index: number) => (
// //                           <li key={index} className="py-2 px-4 hover:bg-gray-100">
// //                             <input
// //                               type="checkbox"
// //                               checked={selectedDevices.includes(deviceName)}
// //                               onChange={() => handleDeviceSelection(deviceName)}
// //                             />
// //                             <span className="ml-2">{deviceName}</span>
// //                           </li>
// //                         ))}
// //                       </ul>
// //                     </div>
// //                   )}
// //                 </div>
// //               </div>
// //             </div>

// //              <div className="flex justify-end mt-5 gap-4">
// //               <div
// //                 className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
// //                 onClick={handleReset}
// //               >
// //                 <span className="text-black px-2">Reset</span>
// //               </div>
// //               <div
// //                 className="relative p-2 gap-8 rounded-md bg-[green] cursor-pointer"
// //                 onClick={handleClick}
// //               >
// //                 <span className="text-white">Submit</span>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default MeterReadingData


// // // import{useEffect, useRef, useState} from "react";
// // // import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// // // import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// // // import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// // // import { useParams } from "react-router-dom";
// // // import { http } from "../../helper/http";
// // // import { useUserState } from "../../app-context/auth-context/AuthState";
// // // import { toast } from "react-toastify";
// // // import dayjs, { Dayjs } from "dayjs";
// // // import 'jspdf-autotable';
// // // import { DatePicker } from '@mui/x-date-pickers';
// // // import jsPDF from "jspdf";
// // // import 'jspdf-autotable';
// // // import * as XLSX from "xlsx";
// // // import { getReportAll1, getReportAll2 } from "../../common-function/Report";

// // // import utc from 'dayjs/plugin/utc';
// // // import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// // // import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// // // import {
// // //   downloadExcelReportForMeterReadin,
// // //   downloadPDFConsumption,
// // //   downloadPDFMetadata,
// // //   generateExcelConsumptionReport,
// // //   generateExcelConsumptionReportHoury,
// // //   downloadPDFConsumptionHoury,
// // //   generateExcelConsumptionReportDaily,
// // //   downloadPDFConsumptionDaily,
// // //   generateReportPDFForMetadata
// // // } from "../../common-function/Reportgenerate";
// // // dayjs.extend(utc);
// // // dayjs.extend(isSameOrAfter);
// // // dayjs.extend(isSameOrBefore);
// // // const baseUrl = process.env.REACT_APP_BASE_URL;


// // // const MeterReadingData: React.FC = () => {
// // //   const [reportFormat, setReportFormat] = useState<string>("excel");
// // //   const [method, setMethod] = useState<string>("manual");
// // //   const [startDate, setStartDate] = useState<any>(null);
// // //   const [endDate, setEndDate] = useState<any>(null);
// // //   const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
// // //   const [showDevices, setShowDevices] = useState<boolean>(false);
// // //   const [devices, setDevices] = useState<string[]>([]);
// // //   const [searchTerm, setSearchTerm] = useState<string>('');
// // //   const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
// // //   const { customerId } = useParams();
// // //   const { users } = useUserState();
// // //   const [reportType, setReportType] = useState("meterReadingData");
// // //   const [selectAllDevices, setSelectAllDevices] = useState<boolean>(false);
// // //   const [apiData, setApiData] = useState<any>(null);
// // //   const dropdownRef = useRef<HTMLDivElement>(null);
 
// // //   console.log('apiData',apiData)

// // //   function calculateFlow(_data: any, i: any) {
// // //     if (i === _data.length - 1) {
// // //       return getSanitizedValue(0);
// // //     }
// // //     const currentData = _data[i];
// // //     const previousData = _data[i + 1];

// // //     if (!previousData) {
// // //       return getSanitizedValue(0); // or some other default value
// // //     }

// // //     if (
// // //       currentData["Differential Total Flow"] === "ERROR" ||
// // //       previousData["Differential Total Flow"] === "ERROR"
// // //     ) {
// // //       return getSanitizedValue(0);
// // //     }

// // //     const currentFlow =
// // //       currentData["Differential Total Flow"] !== "ERROR"
// // //         ? +currentData["Differential Total Flow"]
// // //         : 0;
// // //     const previousFlow =
// // //       previousData["Differential Total Flow"] !== "ERROR"
// // //         ? +previousData["Differential Total Flow"]
// // //         : 0;
// // //     const timeDiff =
// // //       +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
// // //     let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
// // //     if (flow < 0) {
// // //       flow = 0;
// // //     }
// // //     const v = getSanitizedValue(flow * 0.001);
// // //     if (isNaN(v)) {
// // //       return 0;
// // //     }

// // //     return v;
// // //   }

// // //   function getSanitizedValue(value: any) {
// // //     if (typeof value === "number") {
// // //       return value.toFixed(3);
// // //     } else {
// // //       return value;
// // //     }
// // //   }

// // //   function formatTimestamp(timestamp: string) {
// // //     return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
// // //   }

// // //   useEffect(() => {
// // //     const fetchDataOverview = async () => {
// // //       try {
// // //         const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
// // //         const devices1 = response1.devices || [];
// // //         const deviceNames1 = devices1.map((device: any) => device.deviceName).filter(Boolean);

// // //         const response2 = (await http(`${baseUrl}/assign-device?userId=${users.user.id}`)) as any;
// // //         const devices2 = Array.isArray(response2.devices) ? response2.devices : 
// // //                          (Array.isArray(response2.devices?.devices) ? response2.devices.devices : []);
// // //         const deviceNames2 = devices2.map((device: any) => {
// // //           return device.Device?.deviceName || device.deviceName;
// // //         }).filter(Boolean);

// // //         const allDeviceNames = [...deviceNames1, ...deviceNames2];
// // //         const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
// // //         setDevices(uniqueDeviceNames);
// // //       } catch (error) {
// // //         console.error('Error fetching data:', error);
// // //       }
// // //     };

// // //     fetchDataOverview();
// // //   }, [users.user.id]);

// // //   useEffect(() => {
// // //     const filtered = devices.filter(device => 
// // //       device.toLowerCase().includes(searchTerm.toLowerCase())
// // //     );
// // //     setFilteredDevices(filtered);
// // //   }, [searchTerm, devices]);

// // //   useEffect(() => {
// // //     function handleClickOutside(event: MouseEvent) {
// // //       if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
// // //         setShowDevices(false);
// // //       }
// // //     }

// // //     document.addEventListener("mousedown", handleClickOutside);
// // //     return () => {
// // //       document.removeEventListener("mousedown", handleClickOutside);
// // //     };
// // //   }, []);

// // //   const handleToggleDevices = () => {
// // //     setShowDevices(!showDevices);
// // //   };

// // //   const handleDeviceSelection = (device: string) => {
// // //     if (selectedDevices.includes(device)) {
// // //       setSelectedDevices(selectedDevices.filter((d) => d !== device));
// // //     } else {
// // //       setSelectedDevices([...selectedDevices, device]);
// // //     }
// // //   };

// // //   const handleSelectAllDevices = (checked: boolean) => {
// // //     if (checked) {
// // //       setSelectedDevices(devices);
// // //     } else {
// // //       setSelectedDevices([]);
// // //     }
// // //     setSelectAllDevices(checked);
// // //   };

// // //   const handleReset = () => {
// // //     setStartDate(null);
// // //     setEndDate(null);
// // //     setApiData(null);
// // //     setReportFormat("excel");
// // //     setMethod("manual");
// // //     setSelectedDevices([]);
// // //   };

  
// // //   const fetchData = async (startD: Date, endD: Date) => {
// // //     try {
// // //       const response = await http(
// // //         `${baseUrl}/report?startDate=${startD.toISOString()}&endDate=${endD.toISOString()}&customerId=${customerId}`
// // //       ) as {
// // //         deviceName: string;
// // //         data: { status: number; data: any[] };
// // //         template: number;
// // //       }[];
  
// // //       setApiData(response);
  
// // //       let _data: any[] = [];
  
// // //       for (const obj of response) {
  
  
// // //         if (Array.isArray(obj.data.data)) {
// // //           if (obj.template === 5) {
// // //             if (reportType === "meterReadingData") {
// // //               const processedData = obj.data.data.map((d: any, i: number) => {
// // //                 return {
// // //                   logId: d.logId,
// // //                   timestamp: formatTimestamp(d.timeStamp),
// // //                   flow: calculateFlow(obj.data.data, i),
// // //                   flowunit: "m³/hr",
// // //                   positivecumulative:
// // //                     d["Forward Total Flow"] !== "ERROR"
// // //                       ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
// // //                       : "ERROR",
// // //                   negativecumulative:
// // //                     d["Reverse Total Flow"] !== "ERROR"
// // //                       ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
// // //                       : "ERROR",
// // //                   total:
// // //                     d["Differential Total Flow"] !== "ERROR"
// // //                       ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
// // //                       : "ERROR",
// // //                   totalunit: "m³",
// // //                   rssi: d.rssi,
// // //                 };
// // //               });
// // //               _data.push(...processedData);
// // //             } else if (reportType === "consumptionSummary") {
// // //               const processedData = obj.data.data.map((d: any, i: number) => {
// // //                 return {
// // //                   logId: d.logId,
// // //                   timestamp: formatTimestamp(d.timeStamp),
// // //                   forwardTotalFlow:
// // //                     d["Forward Total Flow"] !== "ERROR"
// // //                       ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
// // //                       : "ERROR",
// // //                 };
// // //               });
// // //               _data.push(...processedData);
// // //             }
// // //           } else if (obj.template === 6) {
// // //             // process data for template 6
// // //           } else if (obj.template === 7) {
// // //             // process data for template 7
// // //           }
// // //         } else {
// // //           console.error("obj.data.data is not an array", obj);
// // //         }
// // //       }
  
// // //     } catch (error) {
// // //       console.error(error);
// // //       toast.error("Failed to fetch data");
// // //     }
// // //     };

// // //   useEffect(() => {
// // //     if (startDate && endDate) {
// // //       fetchData(startDate.toDate(), endDate.toDate());
// // //     }
// // //   }, [startDate, endDate]);

// // //   const handleSubmit = () => {
// // //     if (selectedDevices.length === 0) {
// // //       toast.error("Please select at least one device");
// // //       return;
// // //     }

// // //     if (reportFormat === "excel") {
// // //       downloadExcelReportForMeterReadin(selectedDevices, apiData, startDate, endDate, users);
// // //     } else if (reportFormat === "PDF") {
// // //       downloadPDFMetadata(selectedDevices, apiData, startDate, endDate, users);
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //      <div className="px-4">
// // //      <div className="border w-[562px] px-2 py-4 rounded-md">
// // //       <div className="grid grid-cols-2 justify-items-start mt-4 gap-4">
// // //         <div className="w-full">
// // //           <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// // //             Select Report Format
// // //           </p>
// // //           <div className="mt-2">
// // //             <select
// // //               className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
// // //               value={reportFormat}
// // //               onChange={(e) => setReportFormat(e.target.value)}
// // //             >
// // //               <option value="selectReportFormat">Select Report Format</option>
// // //               <option value="excel">Excel</option>
// // //               <option value="PDF">PDF</option>
// // //             </select>
// // //           </div>
// // //         </div>
// // //         <div className="w-full">
// // //           <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// // //             Method
// // //           </p>
// // //           <div className="mt-2">
// // //             <select
// // //               className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
// // //               value={method}
// // //               onChange={(e) => setMethod(e.target.value)}
// // //             >
// // //               <option value="manual">Manual</option>
// // //             </select>
// // //           </div>
// // //         </div>
// // //       </div>

// // //       <div className="mt-4">
// // //         <LocalizationProvider dateAdapter={AdapterDayjs}>
// // //           <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
// // //             <div className="w-full">
// // //               <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// // //                 Start Date
// // //               </p>
// // //               <DateTimePicker
// // //                 value={startDate}
// // //                 onChange={(e: any) => setStartDate(e)}
// // //                 className="w-[100%] bg-[#F8FAFC]"
// // //               />
// // //             </div>
// // //             <div className="w-full">
// // //               <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// // //                 End Date
// // //               </p>
// // //               <DateTimePicker
// // //                 value={endDate}
// // //                 onChange={(e: any) => setEndDate(e)}
// // //                 className="w-[100%] bg-[#F8FAFC] mt-2"
// // //               />
// // //             </div>
// // //           </div>
// // //         </LocalizationProvider>
// // //       </div>

// // //       <div className="w-full mt-4" ref={dropdownRef}>
// // //         <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
// // //           Select Devices
// // //         </p>
// // //         <div className="mt-2">
// // //           <div className="relative">
// // //             <button
// // //               className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 font-semibold py-4 px-4 w-full text-left"
// // //               onClick={handleToggleDevices}
// // //             >
// // //               {selectedDevices.length > 0
// // //                 ? selectedDevices.join(", ")
// // //                 : "Select Devices"}
// // //             </button>
// // //             {showDevices && (
// // //               <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
// // //                 <div className="sticky top-0 bg-white p-2">
// // //                   <input
// // //                     type="text"
// // //                     placeholder="Search devices..."
// // //                     value={searchTerm}
// // //                     onChange={(e) => setSearchTerm(e.target.value)}
// // //                     className="w-full p-2 border rounded"
// // //                   />
// // //                 </div>
// // //                 <ul>
// // //                   <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
// // //                     <input
// // //                       type="checkbox"
// // //                       checked={selectAllDevices}
// // //                       onChange={(e) => handleSelectAllDevices(e.target.checked)}
// // //                     />
// // //                     <span className="ml-2">Select All Devices</span>
// // //                   </li>
// // //                   {filteredDevices.map((deviceName: string, index: number) => (
// // //                     <li key={index} className="py-2 px-4 hover:bg-gray-100">
// // //                       <input
// // //                         type="checkbox"
// // //                         checked={selectedDevices.includes(deviceName)}
// // //                         onChange={() => handleDeviceSelection(deviceName)}
// // //                       />
// // //                       <span className="ml-2">{deviceName}</span>
// // //                     </li>
// // //                   ))}
// // //                 </ul>
// // //               </div>
// // //             )}
// // //           </div>
// // //         </div>
// // //       </div>

// // //       <div className="flex justify-end mt-5 gap-4">
// // //         <div
// // //           className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
// // //           onClick={handleReset}
// // //         >
// // //           <span className="text-black px-2">Reset</span>
// // //         </div>
// // //         <div
// // //           className="relative p-2 gap-8 rounded-md bg-[green] cursor-pointer"
// // //           onClick={handleSubmit}
// // //         >
// // //           <span className="text-white">Submit</span>
// // //         </div>
// // //       </div>

// // //       </div>
// // //       </div>
// // //     </>
// // //   );
// // // };

// // // export default MeterReadingData;

// // MeterReadingData.tsx
// import React, { useState, useEffect, useRef } from 'react';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { useUserState } from "../../app-context/auth-context/AuthState";
// import { toast } from "react-toastify";
// import dayjs from "dayjs";
// import { http } from "../../helper/http";
// import { downloadExcelReportForMeterReadin, downloadPDFMetadata } from "../../common-function/Reportgenerate";

// const baseUrl = process.env.REACT_APP_BASE_URL;

// const MeterReadingData: React.FC = () => {
//   const [startDate, setStartDate] = useState<any>(null);
//   const [endDate, setEndDate] = useState<any>(null);
//   const [apiData, setApiData] = useState<any>(null);
//   const [reportFormat, setReportFormat] = useState("excel");
//   const [method, setMethod] = useState("manual");
//   const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
//   const { users } = useUserState();
//   const [devices, setDevices] = useState<any[]>([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
//   const [showDevices, setShowDevices] = useState(false);
//   const [selectAllDevices, setSelectAllDevices] = useState(false);

//   const dropdownRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         const response1 = await http(`${baseUrl}/device?alldevice=1`) as any;
//         const devices1 = response1.devices || [];
//         const deviceNames1 = devices1.map((device: any) => device.deviceName).filter(Boolean);

//         const response2 = await http(`${baseUrl}/assign-device?userId=${users.user.id}`) as any;
//         const devices2 = Array.isArray(response2.devices) ? response2.devices : 
//                          (Array.isArray(response2.devices?.devices) ? response2.devices.devices : []);
//         const deviceNames2 = devices2.map((device: any) => device.Device?.deviceName || device.deviceName).filter(Boolean);

//         const allDeviceNames = [...deviceNames1, ...deviceNames2];
//         const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
//         setDevices(uniqueDeviceNames);
//       } catch (error) {
//         console.error('Error fetching devices:', error);
//       }
//     };

//     fetchDevices();
//   }, [users.user.id]);

//   useEffect(() => {
//     const filtered = devices.filter(device => 
//       device.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredDevices(filtered);
//   }, [searchTerm, devices]);

//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent) {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
//         setShowDevices(false);
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const fetchData = async (startD: Date, endD: Date) => {
//     try {
//       const response = await http(
//         `${baseUrl}/report?startDate=${startD.toISOString()}&endDate=${endD.toISOString()}&customerId=${users.user.id}`
//       ) as any;
//       setApiData(response);
//     } catch (error) {
//       console.error(error);
//       toast.error("Failed to fetch data");
//     }
//   };

//   useEffect(() => {
//     if (startDate && endDate) {
//       fetchData(startDate.toDate(), endDate.toDate());
//     }
//   }, [startDate, endDate]);

//   const handleToggleDevices = () => {
//     setShowDevices(!showDevices);
//   };
  
//   const handleDeviceSelection = (device: string) => {
//     if (selectedDevices.includes(device)) {
//       setSelectedDevices(selectedDevices.filter((d) => d !== device));
//     } else {
//       setSelectedDevices([...selectedDevices, device]);
//     }
//   };

//   const handleSelectAllDevices = (checked: boolean) => {
//     if (checked) {
//       setSelectedDevices(devices);
//     } else {
//       setSelectedDevices([]);
//     }
//     setSelectAllDevices(checked);
//   };
  
//   const handleReset = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setApiData(null);
//     setReportFormat("excel");
//     setMethod("manual");
//     setSelectedDevices([]);
//   };

//   const handleSubmit = () => {
//     if (selectedDevices.length === 0) {
//       toast.error("Please select at least one device");
//       return;
//     }
  
//     if (reportFormat === "excel") {
//       downloadExcelReportForMeterReadin(selectedDevices, apiData, startDate, endDate, users);
//     } else if (reportFormat === "PDF") {
//       downloadPDFMetadata(selectedDevices, apiData, startDate, endDate, users);
//     }
//   };

//   return (
//     <div className="px-4">
//       <div className="border w-[562px] px-2 py-4 rounded-md">
//         <div className="font-inter p-2 text-xl font-bold leading-5 tracking-wider text-left text-gray-800">
//           Meter Reading Data Report Config
//         </div>
//         <div className="mx-2 mt-2">
//           <div className="grid grid-cols-2 justify-items-start mt-4 gap-4">
//             <div className="w-full">
//               <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
//                 Select Report Format
//               </p>
//               <div className="mt-2">
//                 <select
//                   className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
//                   value={reportFormat}
//                   onChange={(e) => setReportFormat(e.target.value)}
//                 >
//                   <option value="excel">Excel</option>
//                   <option value="PDF">PDF</option>
//                 </select>
//               </div>
//             </div>
//             <div className="w-full">
//               <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
//                 Method
//               </p>
//               <div className="mt-2">
//                 <select
//                   className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
//                   value={method}
//                   onChange={(e) => setMethod(e.target.value)}
//                 >
//                   <option value="manual">Manual</option>
//                 </select>
//               </div>
//             </div>
//           </div>

//           <div className="mt-4">
//             <LocalizationProvider dateAdapter={AdapterDayjs}>
//               <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
//                 <div className="w-full">
//                   <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
//                     Start Date
//                   </p>
//                   <DateTimePicker
//                     value={startDate}
//                     onChange={(e: any) => setStartDate(e)}
//                     className="w-[100%] bg-[#F8FAFC]"
//                   />
//                 </div>
//                 <div className="w-full">
//                   <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
//                     End Date
//                   </p>
//                   <DateTimePicker
//                     value={endDate}
//                     onChange={(e: any) => setEndDate(e)}
//                     className="w-[100%] bg-[#F8FAFC] mt-2"
//                   />
//                 </div>
//               </div>
//             </LocalizationProvider>
//           </div>

//           <div className="w-full mt-4" ref={dropdownRef}>
//             <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
//               Select Devices
//             </p>
//             <div className="mt-2">
//               <div className="relative">
//                 <button
//                   className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 font-semibold py-4 px-4 w-full text-left"
//                   onClick={handleToggleDevices}
//                 >
//                   {selectedDevices.length > 0
//                     ? selectedDevices.join(", ")
//                     : "Select Devices"}
//                 </button>
//                 {showDevices && (
//                   <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
//                     <div className="sticky top-0 bg-white p-2">
//                       <input
//                         type="text"
//                         placeholder="Search devices..."
//                         value={searchTerm}
//                         onChange={(e) => setSearchTerm(e.target.value)}
//                         className="w-full p-2 border rounded"
//                       />
//                     </div>
//                     <ul>
//                       <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
//                         <input
//                           type="checkbox"
//                           checked={selectAllDevices}
//                           onChange={(e) => handleSelectAllDevices(e.target.checked)}
//                         />
//                         <span className="ml-2">Select All Devices</span>
//                       </li>
//                       {filteredDevices.map((deviceName: string, index: number) => (
//                         <li key={index} className="py-2 px-4 hover:bg-gray-100">
//                           <input
//                             type="checkbox"
//                             checked={selectedDevices.includes(deviceName)}
//                             onChange={() => handleDeviceSelection(deviceName)}
//                           />
//                           <span className="ml-2">{deviceName}</span>
//                         </li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-end mt-5 gap-4">
//             <div
//               className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
//               onClick={handleReset}
//             >
//               <span className="text-black px-2">Reset</span>
//             </div>
//             <div
//               className="relative p-2 gap-8 rounded-md bg-[green] cursor-pointer"
//               onClick={handleSubmit}
//             >
//               <span className="text-white">Submit</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MeterReadingData;
import React, { useState, useEffect, useRef } from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { downloadExcelReportForMeterReadin, downloadPDFMetadata } from "../../common-function/Reportgenerate";

const baseUrl = process.env.REACT_APP_BASE_URL;

const MeterReadingData = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [apiData, setApiData] = useState<any>(null);
  const [reportFormat, setReportFormat] = useState("excel");
  const [method, setMethod] = useState("manual");
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const { customerId } = useParams();
  const { users } = useUserState();
  const [devices, setDevices] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
  const [showDevices, setShowDevices] = useState(false);
  const [selectAllDevices, setSelectAllDevices] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const filtered = devices.filter(device => 
      device.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDevices(filtered);
  }, [searchTerm, devices]);

  useEffect(() => {
    const fetchDataOverview = async () => {
      try {
        const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
        const devices1 = response1.devices || [];
        const deviceNames1 = devices1.map((device: any) => device.deviceName).filter(Boolean);

        const response2 = (await http(`${baseUrl}/assign-device?userId=${users.user.id}`)) as any;
        const devices2 = Array.isArray(response2.devices) ? response2.devices : 
                         (Array.isArray(response2.devices?.devices) ? response2.devices.devices : []);
        const deviceNames2 = devices2.map((device: any) => {
          return device.Device?.deviceName || device.deviceName;
        }).filter(Boolean);

        const allDeviceNames = [...deviceNames1, ...deviceNames2];
        const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
        setDevices(uniqueDeviceNames);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataOverview();
  }, [users.user.id]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDevices(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate.toDate(), endDate.toDate());
    }
  }, [startDate, endDate]);
  
  const fetchData = async (startD: Date, endD: Date) => {
    try {
      const response = await http(
        `${baseUrl}/report?startDate=${startD.toISOString()}&endDate=${endD.toISOString()}&customerId=${customerId}`
      ) as {
        deviceName: string;
        data: { status: number; data: any[] };
        template: number;
      }[];
      console.log('response',response)
      setApiData(response);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };

  const handleToggleDevices = () => {
    setShowDevices(!showDevices);
  };
  
  const handleDeviceSelection = (device: string) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices(selectedDevices.filter((d) => d !== device));
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }
  };

  const handleSelectAllDevices = (checked: boolean) => {
    if (checked) {
      setSelectedDevices(devices);
    } else {
      setSelectedDevices([]);
    }
    setSelectAllDevices(checked);
  };
  
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setApiData(null);
    setReportFormat("excel");
    setMethod("manual");
    setSelectedDevices([]);
  };

  const handleClick = () => {
    if (selectedDevices.length === 0) {
      toast.error("Please select at least one device");
      return;
    }
  
    if (reportFormat === "excel") {
      downloadExcelReportForMeterReadin(selectedDevices, apiData, startDate, endDate, users);
    } else if (reportFormat === "PDF") {
      downloadPDFMetadata(selectedDevices, apiData, startDate, endDate, users);
    }
  };

  return (
    <div className="px-4 mt-6">
      <div className="border w-[562px] px-2 py-4 rounded-md">
        <div className="font-inter p-2 text-xl font-bold leading-5 tracking-wider text-left text-gray-800">
          Meter Reading Data Report Config
        </div>
        <div className="mx-2 mt-2">
          <div className="grid grid-cols-2 justify-items-start mt-4 gap-4">
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Report Format
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
                  value={reportFormat}
                  onChange={(e) => setReportFormat(e.target.value)}
                >
                  <option value="selectReportFormat">
                    Select Report Format
                  </option>
                  <option value="excel">Excel</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Method
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <option value="manual">Manual</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
                <div className="w-full">
                  <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    Start Date
                  </p>
                  <DateTimePicker
                    value={startDate}
                    onChange={(e: any) => setStartDate(e)}
                    className="w-[100%] bg-[#F8FAFC] "
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    End Date
                  </p>
                  <DateTimePicker
                    value={endDate}
                    onChange={(e: any) => setEndDate(e)}
                    className="w-[100%] bg-[#F8FAFC] mt-2"
                  />
                </div>
              </div>
            </LocalizationProvider>
          </div>

          <div className="w-full mt-4" ref={dropdownRef}>
            <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
              Select Devices
            </p>
            <div className="mt-2">
              <div className="relative">
                <button
                  className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 font-semibold py-4 px-4 w-full text-left"
                  onClick={handleToggleDevices}
                >
                  {selectedDevices.length > 0
                    ? selectedDevices.join(", ")
                    : "Select Devices"}
                </button>
                {showDevices && (
                  <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                    <div className="sticky top-0 bg-white p-2">
                      <input
                        type="text"
                        placeholder="Search devices..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <ul>
                      <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
                        <input
                          type="checkbox"
                          checked={selectAllDevices}
                          onChange={(e) => handleSelectAllDevices(e.target.checked)}
                        />
                        <span className="ml-2">Select All Devices</span>
                      </li>
                      {filteredDevices.map((deviceName: string, index: number) => (
                        <li key={index} className="py-2 px-4 hover:bg-gray-100">
                          <input
                            type="checkbox"
                            checked={selectedDevices.includes(deviceName)}
                            onChange={() => handleDeviceSelection(deviceName)}
                          />
                          <span className="ml-2">{deviceName}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-5 gap-4">
            <div
              className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
              onClick={handleReset}
            >
              <span className="text-black px-2">Reset</span>
            </div>
            <div
              className="relative p-2 gap-8 rounded-md bg-[green] cursor-pointer"
              onClick={handleClick}
            >
              <span className="text-white">Submit</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterReadingData;