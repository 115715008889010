import { UserOnboard, UserOnboardPagination } from "../../types/UserOnboardTypes";

export interface UserState {
  userOnBoard: UserOnboardPagination;
}

export type UserAction =
  | { type: "SET_USER_ONBOARD"; payload: UserOnboardPagination }
//   | { type: "SET_CUSTOMERS_ALL"; payload: Customer[] }
//   | { type: "SET_CUSTOMERS_WITH_PAGE"; payload: PaginatedCustomers }
  | { type: "ADD_USER_ONBOARD"; payload: { userOnboards: UserOnboard, totalItems: number } }
  | { type: "UPDATE_USER_ONBOARD"; payload: { id: number, userOnboards: UserOnboard } }
  | { type: "SET_USER_ONBOARD_PAGE", payload: UserOnboardPagination;}
//   | {
//     type: "UPDATE_CUSTOMERS_STATUS";
//     payload: { id: number; status: boolean };
//   };

export default function UserReducer(
  state: UserState,
  action: UserAction
): UserState {
  const { type, payload } = action;

  switch (type) {
    case "SET_USER_ONBOARD":
      return { ...state, userOnBoard: payload };
    case "SET_USER_ONBOARD_PAGE":
      return { ...state, userOnBoard: payload };
    // case "SET_CUSTOMERS_ALL":
    //   return {
    //     ...state,
    //     customers: {
    //       customers: action.payload,
    //       currentPage: 1, // Assuming some default values for pagination
    //       totalPages: 1,
    //       totalItems: action.payload.length
    //     }
    //   };
    // case "SET_CUSTOMERS_WITH_PAGE":
    //   return { ...state, customers: payload };
    case "ADD_USER_ONBOARD":
      const { userOnboards: newUser, totalItems } = payload;
        console.log(newUser);
        
      return {
        ...state,
        userOnBoard: {
          ...state.userOnBoard,
          users: [newUser, ...state.userOnBoard.users],
          totalItems: totalItems + 1
        },
      };
    case "UPDATE_USER_ONBOARD":
      const { id: updateId, userOnboards } = payload;
      const updatedUserOnBoard = state.userOnBoard.users.map((user) =>
        user.id === updateId ? { ...user, ...userOnboards } : user
      );
      return {
        ...state,
        userOnBoard: {
          ...state.userOnBoard,
          users: updatedUserOnBoard,
        },
      };
    // case "UPDATE_CUSTOMERS_STATUS":
    //   const { id, status } = payload;
    //   const customerToUpdate = state.customers.customers.find(
    //     (customer) => customer.id === id
    //   );
    //   if (customerToUpdate) {
    //     // Update the status of the found device
    //     customerToUpdate.status = status;
    //     // Clone the devices array to trigger a state update
    //     const updatedDevicesAfterStatusUpdate = [...state.customers.customers];
    //     // Update the state with the new devices array
    //     return {
    //       ...state,
    //       customers: {
    //         ...state.customers,
    //         customers: updatedDevicesAfterStatusUpdate,
    //       },
    //     };
    //   }
    //   return state;
    default:
      return state;
  }
}
