interface DataItem {
    timestamp: string;
    total: string;
  }
  
  interface HourlyData {
    firstTimeStamp: string;
    lastTimeStamp: string;
    firstPositivecumulative: string;
    lastPositivecumulative: string;
    hour: string;
  }


export function filterDataFromEachHourLast24Hours(
    data: DataItem[]
  ): HourlyData[] {
    data.sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
  
    // Calculate the timestamp for 24 hours ago from the current time
    const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  
    // Filter the array to include only items from the last 24 hours from the current hour
    const last24HoursData = data.filter(
      (item) => new Date(item.timestamp) >= twentyFourHoursAgo
    );
   
    // Initialize an array to store the results
    const result: HourlyData[] = [];
  
    // Process the data
    last24HoursData.forEach((item) => {
      const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here
  
      const index = result.findIndex(
        (hourlyData) => hourlyData.hour === hourTimestamp
      );
  
      if (index === -1) {
        // If the hour doesn't exist in the result array, initialize it
        result.push({
          firstTimeStamp: item.timestamp,
          lastTimeStamp: item.timestamp,
          firstPositivecumulative: item.total,
          lastPositivecumulative: item.total,
          hour: hourTimestamp,
        });
      } else {
        // Update the lastTimeStamp and lastPositivecumulative for the current hour
        result[index].lastTimeStamp = item.timestamp;
        result[index].lastPositivecumulative = item.total;
      }
    });
  
    return result;
  }
  
  interface HourlyData {
    firstTimeStamp: string;
    lastTimeStamp: string;
    firstPositivecumulative: string;
    lastPositivecumulative: string;
    hour: string;
  }
  // STEP 2
  // this function will destructure the array ofHourlyData data based on need it
  export function processHourlyData(inputData: HourlyData[]): HourlyData[] {
    const processedData: HourlyData[] = [];
  
    // Process the first object separately
    processedData.push({
      firstTimeStamp: inputData[0].firstTimeStamp,
      lastTimeStamp: inputData[0].lastTimeStamp,
      firstPositivecumulative: inputData[0].firstPositivecumulative,
      lastPositivecumulative: inputData[0].lastPositivecumulative,
      hour: inputData[0].hour,
    });
  
    // Process the rest of the objects
    for (let i = 1; i < inputData.length; i++) {
      const currentHour = inputData[i];
      const previousHour = inputData[i - 1];
  
      processedData.push({
        firstTimeStamp: previousHour.lastTimeStamp,
        lastTimeStamp: currentHour.lastTimeStamp,
        firstPositivecumulative: previousHour.lastPositivecumulative,
        lastPositivecumulative: currentHour.lastPositivecumulative,
        hour: currentHour.hour,
      });
    }
  
    return processedData;
  }
  
  //  STEP 3
  
  interface HourlyData {
    firstTimeStamp: string;
    lastTimeStamp: string;
    firstPositivecumulative: string;
    lastPositivecumulative: string;
    hour: string;
  }
  
  interface ConsumptionData {
    hour: string;
    consumption: number;
    firstTimeStamp: string;
    lastTimeStamp: string;
  }
  // this will return ths consumtion data
  export function processConsumptionData(
    inputData: HourlyData[]
  ): ConsumptionData[] {
    const consumptionData: ConsumptionData[] = [];
  
    for (let i = 0; i < inputData.length; i++) {
      const currentHour = inputData[i];
  
      const consumption: ConsumptionData = {
        hour: currentHour.hour,
        consumption: Math.abs(
          parseFloat(currentHour.firstPositivecumulative) -
            parseFloat(currentHour.lastPositivecumulative)
        ),
        firstTimeStamp: currentHour.firstTimeStamp,
        lastTimeStamp: currentHour.lastTimeStamp,
      };
  
      consumptionData.push(consumption);
    }
  
    return consumptionData;
  }