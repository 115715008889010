import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  addNewCustomers,
  updateCustomer,
} from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { Customer, CustomerApiResponse } from "../../../types/CustomerTypes";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";
import CommonLoader from "../../../ui/CommonLoader";
import { Formik, ErrorMessage } from "formik";
import { validationSchema } from "./ValidationCustomer";

interface AddCustomerFormModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: Customer[];
  uniqueId: string;
}

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;
const AddCustomerForm: React.FC<AddCustomerFormModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState("normal");
  const [subdomain, setSubdomain] = useState("");
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => { }, [name, email]);
  const { customers, dispatch } = useCustomerState();

  useEffect(() => {
    if (uniqueId === "EDIT" && data) {
      const customerToUse = Array.isArray(data) ? data[0] : data;
      id = customerToUse?.id;
      setName(customerToUse.name);
      setEmail(customerToUse.email);
    }
  }, [uniqueId, data]);

  const handleRadioChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === "string") {
        setLogo(reader.result);

      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    <CommonLoader loading={true} />;
    const data = { name, email };

    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/customer`,
          "POST",
          data
        )) as CustomerApiResponse;
        addNewCustomers(dispatch, {
          customers: resp.customers,
          totalItems: customers.totalItems,
        });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/customer?id=${id}`,
          "PUT",
          data
        )) as CustomerApiResponse;
        updateCustomer(dispatch, { id, customers: resp.customers });
        toast.success(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
    toggleFormFun();
  };

  return (
    <>
      {/* Popup onclick of Add button */}
      <Formik
        initialValues={{ name: "", email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Dialog open={toggleForm} onClose={toggleFormFun}>
          <DialogTitle>
            {" "}
            {uniqueId === "ADD" ? "Add Customer" : "Update Customer"}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              placeholder="Enter The Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <ErrorMessage name="name" component="div" />
            <TextField
              label="Email"
              placeholder="Enter The Email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />

            {/* <FormControl component="fieldset">
              <FormLabel component="legend">Select Option</FormLabel>
              <RadioGroup
                row
                value={selectedOption}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio />}
                  label="Normal"
                />
                <FormControlLabel
                  value="whiteLabel"
                  control={<Radio />}
                  label="White Label"
                />
              </RadioGroup>
            </FormControl>

            {selectedOption === "normal" && (
              <>
                <TextField
                  label="Name"
                  placeholder="Enter The Name"
                  fullWidth
                  margin="normal"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
                <ErrorMessage name="name" component="div" />
                <TextField
                  label="Email"
                  placeholder="Enter The Email"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </>
            )}
            {selectedOption === "whiteLabel" && (
              <>
                <TextField
                  label="Name"
                  placeholder="Enter The Name"
                  fullWidth
                  margin="normal"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <ErrorMessage name="name" component="div" />
                <TextField
                  label="Email"
                  placeholder="Enter The Email"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                  label="Sub Domain"
                  placeholder="Enter The Sub-Domain"
                  fullWidth
                  margin="normal"
                  value={subdomain}
                  onChange={(event) => setSubdomain(event.target.value)}
                />

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Upload Logo
                  </label>
                  <input
                    type="file"
                    onChange={handleLogoUpload}
                    className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                  {logo && (
                    <img
                      src={logo as string}
                      alt="Logo Preview"
                      className="mt-4 h-24"
                    />
                  )}
                </div>
              </>
            )} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleFormFun}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary">
              {uniqueId === "ADD" ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      </Formik>
    </>
  );
};

export default AddCustomerForm;
