import React, { useState } from 'react';
import './editprofile.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';


export default function EditProfile() {
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);

    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleOptionSelect = (option: string) => {
        setSelectedOption(option);
        console.log(option);
        setShowDropdown1(false);

        if (option === 'alpha') {
            navigate('/register');
          }
    };

    return (
        <>
            <div className="flex justify-center items-center h-full bg-gray-500">
                <form className="m-4">
                    <div className="flex flex-row gap-[23%]">
                        <div className="flex flex-col gap-6">
                            {/* Image tag goes here */}
                            <div className="w-full border-white text-white flex flex-col gap-1 rounded-md">
                                <label htmlFor="name" className="m-2">Name</label>
                                <input type="text" id="name" placeholder="Enter Name" className="flex items-center bg-[#393a73] border border-white border-solid focus:text-white rounded-md" style={{ padding: "12px 20px" }} />

                            </div>
                            <div className="w-full border-white text-white flex flex-col gap-1 rounded-md">
                                <label htmlFor="email" className="m-2">Email ID</label>
                                <input type="text" id="email" placeholder="Enter Email ID" className="flex items-center p-2 md:p-1 bg-[#393a73] border border-white border-solid focus:text-white rounded-md" style={{ padding: "12px 20px" }} />

                            </div>
                            <div className="w-full border-white text-white flex flex-col gap-1 rounded-md">
                                <label htmlFor="phone" className="m-2">Phone No.</label>
                                <input type="text" id="phone" placeholder="Enter Phone No." className="flex items-center p-2 md:p-1 bg-[#393a73] border border-white border-solid focus:text-white rounded-md" style={{ padding: "12px 20px" }} />

                            </div>
                        </div>
                        <div className="flex flex-col gap-5">

                            {/* 1st DropDown bottom starts here */}
                            <div className="flex flex-col w-[250px] items-start gap-12px relative">
                                <div className="items-start flex relative self-stretch w-full flex-0"><div className="relative flex-1 text-white font-Lato-Regular font-normal text-base tracking-normal leading-normal">Team</div></div>
                                <div className="flex items-center gap-20px p-2 md:p-2 bg-[#393a73] rounded-md border border-white self-stretch w-full flex-0" onClick={() => setShowDropdown1(!showDropdown1)}>
                                    <div className="relative flex-1 text-white font-Lato-Regular font-normal text-base tracking-normal leading-normal"> {selectedOption || 'Choose a team'}</div>
                                    {/* <div className="utility-icons-c">KeyboardArrowDownIcon</div> */}
                                    <KeyboardArrowDownIcon className='absolute w-8 h-8 text-white right-1' />
                                </div>
                                {showDropdown1 && (
                                    <div className="dropdown-content">
                                        {/* dropdown bottom options */}
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('alpha')}>
                                            Alpha
                                        </div>
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('beta')}>
                                            Beta
                                        </div>
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('gamma')}>
                                            Gamma
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* 1st dropdown botton ends here */}

                            <div className="flex flex-col w-[250px] items-start gap-12px relative">
                                <div className="items-start flex relative self-stretch w-full flex-0"><div className="relative flex-1 text-white font-Lato-Regular font-normal text-base tracking-normal leading-normal">Team</div></div>
                                <div className="flex items-center gap-20px p-2 md:p-2 bg-[#393a73] rounded-md border border-white self-stretch w-full flex-0" onClick={() => setShowDropdown2(!showDropdown2)}>
                                    <div className="relative flex-1 text-white font-Lato-Regular font-normal text-base tracking-normal leading-normal"> {selectedOption || 'Choose a team'}</div>
                                    {/* <div className="utility-icons-c">KeyboardArrowDownIcon</div> */}
                                    <KeyboardArrowDownIcon className='absolute w-8 h-8 text-white right-1' />
                                </div>
                                {showDropdown2 && (
                                    <div className="dropdown-content">
                                        {/* dropdown bottom options */}
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('alpha')}>
                                            Alpha
                                        </div>
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('beta')}>
                                            Beta
                                        </div>
                                        <div className="dropdown-option w-[250px] pl-2 text-white bg-[#393a73] hover:bg-blue-600 cursor-pointer rounded-md border border-white" onClick={() => handleOptionSelect('gamma')}>
                                            Gamma
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </form>
            </div>

        </>

    );

};