import user from "../../images/User1.jpg";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const AccountSettings = () => {
  
  return (
    <>
      <div className="mx-auto max-w-270">
        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default ">
              <div className="border-b border-stroke py-4 px-7 ">
                <h3 className="font-medium text-black ">
                  Personal Information
                </h3>
              </div>
              <div className="p-7">
                <form action="#">
                  <div className="mb-5 flex flex-col gap-5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black "
                        htmlFor="fullName"
                      >
                        Full Name
                      </label>
                      <div className="relative flex items-center">
                        <span className="absolute pl-[37px] top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <PersonIcon />
                        </span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-10 pr-4.5 text-black focus:border-primary focus-visible:outline-none "
                          type="text"
                          name="fullName"
                          id="fullName"
                          placeholder="Devid Jhon"
                          defaultValue="Devid Jhon"
                        />
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black "
                        htmlFor="phoneNumber"
                      >
                        Phone Number
                      </label>
                      <div className="relative flex items-center">
                        <span className="absolute pl-[37px] top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <LocalPhoneIcon />
                        </span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-10 px-4.5 text-black focus:border-primary focus-visible:outline-none"
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder="+91 1234567890"
                          defaultValue="+91 1234567890"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label
                      className="mb-3 block text-sm font-medium text-black "
                      htmlFor="emailAddress"
                    >
                      Email Address
                    </label>
                    <div className="relative flex items-center">
                      <span className="absolute pl-[37px] top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <MailOutlineIcon />
                      </span>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 pl-10 pr-4.5 text-black focus:border-primary focus-visible:outline-none "
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        placeholder="abcd1234@gmail.com"
                        defaultValue="abcd1234@gmail.com"
                      />
                    </div>
                  </div>

                  <div className="mb-5">
                    <label
                      className="mb-3 block text-sm font-medium text-black "
                      htmlFor="address"
                    >
                      Address
                    </label>
                    <textarea
                      className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none "
                      name="address"
                      id="address"
                      rows={5}
                      placeholder="Write your Address here"
                      defaultValue="Bengaluru"
                    />
                  </div>

                  <div className="flex justify-end gap-4">
                    <button
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  "
                      type="submit"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="rounded-sm border border-stroke bg-white shadow-default ">
              <div className="border-b border-stroke py-4 px-7 ">
                <h3 className="font-medium text-black ">Your Photo</h3>
              </div>
              <div className="p-7">
                <form action="#">
                  <div className="mb-4 flex items-center gap-3">
                    <div className="h-14 w-14 rounded-full">
                      <img src={user} alt="User" />
                    </div>
                    <div>
                      <span className="mb-1 text-black ">Edit your photo</span>
                      <span className="flex gap-2.5">
                        <button className="text-sm hover:text-primary">
                          Delete
                        </button>
                        <button className="text-sm hover:text-primary">
                          Update
                        </button>
                      </span>
                    </div>
                  </div>

                  <div
                    id="FileUpload"
                    className="relative mb-5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    />
                    <div className="flex flex-col items-center justify-center space-y-3">
                      <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white ">
                        <FileUploadIcon />
                      </span>
                      <p>
                        <span className="text-primary">Click to upload</span> or
                        drag and drop
                      </p>
                      <p className="mt-1">SVG, PNG or JPG </p>
                      <p>(max, 800 X 800px)</p>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4">
                    <button
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1  "
                      type="submit"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-70"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
