import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { Checkbox, Dialog, IconButton } from "@mui/material";
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import CustomLineChart from "../../../components/deviceComponents/CustomLineChart";
import { http } from "../../../helper/http";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { JwtPayload, jwtDecode } from "jwt-decode";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBoxFilter from "../../../components/CheckboxField";
import {
  formatIndianTime,
  formatTimestamp,
  generateCSV,
  reportData1,
} from "../../../common-function/CommonFunction";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { generateCSV1, reportData } from "../../../common-function/Report";


let header: any = [
  "TimeStamp",
  "Flow",
  "Flow unit",
  // "POSITIVECUMULATIVE",
  // "NEGATIVECUMULATIVE",
  "Total",
  "Total unit",
];

let energy: any = [
  "TimeStamp",
  "AverageVoltage",
  "AverageCurrent",
  "TotalActivePower",
  "TotalReactivePower",
  "TotalApparentPower",
  "Frequency",
  "RPhaseVoltage",
  "YPhaseVoltage",
  "YBPhaseVoltage",
  "BPhaseVoltage",
  "RYPhaseVoltage",
  "RBPhaseVoltage",
  "RPhaseCurrent",
  "YPhaseCurrent",
  "BPhaseCurrent",
  "PowerFactor",
  "RPhasePowerFactor",
  "YPhasePowerFactor",
  "BPhasePowerFactor",
  "RPhaseActivePower",
  "YPhaseActivePower",
  "BPhaseActivePower",
  "RPhaseReactivePower",
  "YPhaseReactivePower",
  "BPhaseReactivePower",
  "RPhaseApparentPower",
  "YPhaseApparentPower",
  "BPhaseApparentPower",
  "ActiveEnergy",
  "ReactiveEnergy",
  "ApparentEnergy",

  "rvtgphthd",
  "yvtgphthd",
  "bvtgphthd",
  "rcrtphthd",
  "ycrtphthd",
  "bcrtphthd"
];
const baseUrl = process.env.REACT_APP_BASE_URL;
const Data = () => {
  // const { devices }: any = useDeviceState();

  const location = useLocation();
  const deviceId = location.state?.deviceId;
  const [template, setTemplate]: any = useState(null);
  const [deviceName, setDeviceName]: any = useState("");
  const [headers, setHeaders]: any = useState<any>([]);
  const [optionColumns, setOptionColumns]: any = useState<any[]>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [apiData1, setApiData1] = useState<any>([]);

  // const levelHeaders = ["TimeStamp", "Level"];
  const [selected, setSelected] = useState<any[]>([]);
  const [filterTable, setFilterTable] = useState(false);
  // const [column, setColumn] = useState<any>(headers);
  const [device, setDevice] = useState<any>(null);

  const [token] = useState(window.localStorage.getItem("token"));
  function readRoles(token: any) {
    try {
      const decodedToken: any = jwtDecode<JwtPayload>(token);
      return decodedToken;
    } catch (error) {
      console.log(error);
    }
  }
  const role = readRoles(token);

  // Function to handle click on a single checkbox
  const handleClick = (id: any) => {
    const index = selected.findIndex((item) => item === id);
    setSelected((prevSelected: any) =>
      index === -1
        ? [...prevSelected, id]
        : prevSelected.filter((item: any) => item !== id)
    );
  };

  useEffect(() => {
    fetchDeviceById(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (!device) return;
    // const device: any = devices.devices.find((d: any) => d.id === deviceId);    
    setTemplate(device?.template);
    setDeviceName(device?.deviceName);
    if (device?.template === 5) {
      setHeaders(["#", "TimeStamp", "Flow", "Flow unit", "Total", "Total Unit"]);
    } else if (device?.template === 7) {
      setHeaders(["#", "TimeStamp", "Do", "Ph", "Tds", "Temp"]);
    } else if (
      device?.template === 8 ||
      device?.template === 23 ||
      device?.template === 22
    ) {
      setHeaders(["#", "Timestamp", "X", "Y", "Z"]);
    } else if (device.template === 9) {
      setHeaders(["#", "Timestamp", "Totalizer", "Unit"]);
    } else if (device.template === 10 || device.template === 20) {
      setHeaders(["#", "Timestamp", "Temperature", "Humidity"]);
    } else if (device.template === 12) {
      setHeaders(["#", "TimeStamp", "Ni", "Ph", "Tds", "Temp"]);
    } else if (device.template === 13) {
      setHeaders(["#", "TimeStamp", "Cl", "Ph", "Tds", "Temp", "Turb"]);
    } else if (device.template === 14) {
      setHeaders([
        "#",
        "TimeStamp",
        "AverageVoltage",
        "AverageCurrent",
        "TotalActivePower",
        "TotalReactivePower",
        "TotalApparentPower",
        "Frequency",
        "RPhaseVoltage",
        "YPhaseVoltage",
        "YBPhaseVoltage",
        "BPhaseVoltage",
        "RYPhaseVoltage",
        "RBPhaseVoltage",
        "RPhaseCurrent",
        "YPhaseCurrent",
        "BPhaseCurrent",
        "PowerFactor",
        "RPhasePowerFactor",
        "YPhasePowerFactor",
        "BPhasePowerFactor",
        "RPhaseActivePower",
        "YPhaseActivePower",
        "BPhaseActivePower",
        "RPhaseReactivePower",
        "YPhaseReactivePower",
        "BPhaseReactivePower",
        "RPhaseApparentPower",
        "YPhaseApparentPower",
        "BPhaseApparentPower",
        "ActiveEnergy",
        "ReactiveEnergy",
        "ApparentEnergy",

        "yvtgphthd",
        "bvtgphthd",
        "rcrtphthd",
        "ycrtphthd",
        "bcrtphthd"
      ]);

      // setOptionColumns(enery);
    } else if (device.template === 15) {
      setHeaders(["#", "TimeStamp","Flow","Flow Unit", "Totalizer", "Totalizer Unit"]);
    } else if (device.template === 16) {
      setHeaders(["#", "TimeStamp", "Totalizer", "Flow", "PumpStatus"]);
    } else if (device.template === 17) {
      setHeaders(["#", "TimeStamp", "Ph", "Temp"]);
    } else if (device.template === 18) {
      setHeaders(["#", "TimeStamp", "Tds", "Temp"]);
    } else if (device.template === 19) {
      setHeaders(["#", "TimeStamp", "Cl", "Turb", "Temp"]);
    } else if (device.template === 11) {
      setHeaders(["#", "TimeStamp", "Pump_1", "Pump_2"]);
    } else if (device.template === 21) {
      setHeaders(["#", "TimeStamp", "DeviceStatus"]);
    } else if (device.template === 24) {
      setHeaders([
        "#",
        "TimeStamp", 
        "flow",
        "flowunit",
        "totalizer",
        "totalizerunit",
      ]);
    } else if (device.template === 27) {
      setHeaders([
        "#",
        "Timestamp",
        "Flow",
        "Flowrate",
        "Totalizer",
        "Forward Totalizer",
        "Reverse Totalizer",
        "Battery Level",
        "Unit",
      ]);
    } else if (device.template === 25) {
      setHeaders([
        "#",
        "TimeStamp",
        "Energy_Generation",
        "Energy_Consumption",
        "Fuel_Consumed",
        "SFC",
        "Total_Run_Hours",
        "Active_Power",
        "Voltage",
        "Current",
        "Power_Factor",
        "Dg_Controller",
        "Tank_Level",
      ]);
    } else if (device.template === 6) {
      setHeaders(["#", "TimeStamp", "Level"]);
    } else if (device.template === 26) {
      setHeaders([
        "#",
        "TimeStamp",
        "Mppt_Power",
        "Mppt_Voltage",
        "Mppt_Current",
        "Ac_Current",
        "Ac_Voltage",
        "String_Voltage",
        "String_Current",
        "Inverter_Ambient",
        "Temperature_Inverter_Internal",
        "Inverter_Status",
        "Today_Energy",
        "Total_Energy",
        "Active_Power",
        "Power_Factor",
        "Efficiency",
        "CUF",
        "Battery_Level",
        "Pr_Ratio",
        "Frequency",
      ]);
    } else if (device.template === 28) {
      setHeaders(["#", "TimeStamp", "Temperature"]);
    } else if (device.template === 29) {
      setHeaders(["#", "TimeStamp", "Level", "Unit"]);
    }
    else if (device.template === 31) {
      setHeaders(["#", "TimeStamp", "Totalizer", "Unit", "BatteryValue", "BatteryUnit"]);
    }
    else if (device.template === 32) {
      setHeaders(["#", "TimeStamp", "Flow", "FlowUnit", "Total", "TotalUnit"]);
    }
  }, [device]);

  const fetchDeviceById = async (deviceId: number) => {
    try {
      const current_device = (await http(`/device?id=${deviceId}`)) as any;
      setDevice(current_device.devices);
    } catch (error) {
      console.error('Error fetching device data:', error);
    }
  };

  const chartData = {
    xAxis: [{ data: [1, 2, 3, 5, 8, 10] }],
    series: [{ data: [2, 5.5, 2, 8.5, 1.5, 5] }],
  };

  const [startValue, setStartValue]: any = React.useState<Dayjs | null>(
    dayjs("")
  );
  const [endValue, setEndValue]: any = React.useState<Dayjs | null>(dayjs(""));
  const [selectedOption, setSelectedOption] = useState<"chart" | "table">(
    "table"
  );

  useEffect(() => {
    if (startValue && endValue) {
      const startDate = startValue.toDate();
      const endDate = endValue.toDate();

      if (!isNaN(startDate) && !isNaN(endDate)) {
        getData({ startDate, endDate, deviceId });
      }
    }
  }, [startValue, endValue, deviceId]);

  async function getData({ startDate, endDate, deviceId }: any) {
    try {
      const resp: any = await http(
        `/device-data?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&deviceId=${deviceId}`
      );
      setApiData1(resp);
      let _data = [];
      //       if (template === 5) {
      //   _data = resp.map((d: any, i: any) => {
      //     return {
      //       logId: d.logId,
      //       timestamp: formatTimestamp(d.timeStamp),
      //       flow: calculateFlow(resp, i),
      //       "Flow unit": "m³/hr",
      //       total: d["Forward Total Flow"] !== "ERROR" ?
      //          formatTwoDecimals(d["Forward Total Flow"]) : "ERROR",
      //       "Total unit": "m³",
      //     };
      //   });
      // }
      if (template === 5) {
        _data = resp.map((d: any, i: any) => {
          console.log('d',d)
          if (d["networkName"] === "airtel") {
            return {
              timestamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? d["Forward Total Flow"]
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? d["Reverse Total Flow"]
                  : "ERROR",
              // total:
              //   d["Differential Total Flow"] !== "ERROR"
              //     ? d["Differential Total Flow"]
              //     : "ERROR",
              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          } else {
            return {
              timestamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: calculateFlow(resp, i),
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
                  : "ERROR",
              // total:
              //   d["Differential Total Flow"] !== "ERROR"
              //     ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
              //     : "ERROR",
              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          }
          // return {
          //   logId: d.logId,
          //   timestamp: formatTimestamp(d.timeStamp),
          //   flow: calculateFlow(resp, i),
          //   "Flow unit": "m³/hr",
          //   total: d["Forward Total Flow"] !== "ERROR" ?
          //    getSanitizedValue(+d["Forward Total Flow"] * 0.001) : "ERROR",
          //   //negativecumulative: d["Reverse Total Flow"] !== "ERROR" ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001) : "ERROR",
          //   //total: d["Differential Total Flow"] !== "ERROR" ? getSanitizedValue(+d["Differential Total Flow"] * 0.001) : "ERROR",
          //   "Total unit": "m³",
          //   //rssi: d.rssi,
          // };
        });
      }
      else if (template === 6) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            level: d["Piezo Level"],
          };
        });
      } else if (template === 7) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            do: d["DO"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 8 || template === 22 || template === 23) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            x: d["x"],
            y: d["y"],
            z: d["z"],
          };
        });
      } else if (template === 9) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            totalizer: d["Totalizer"],
            unit: "m³",
          };
        });
      } else if (template === 10) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
          };
        });
      } else if (template === 12) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            ni: d["NI"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 13) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
            turb: d["TURB"],
          };
        });
      } 
      else if (template === 14) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            averagevoltage: d["avg_vtg"],
            averagecurrent: d["avg_crt"],
            totalactivepower: d["total_act_po"],
            totalreactivepower: d["total_react_po"],
            totalapparentpower: d["total_app_po"],
            frequency: d["frequency"],
            rphasevoltage: d["r_ph_vtg"],
            yphasevoltage: d["y_ph_vtg"],
            ybphasevoltage: d["yb_ph_vtg"],
            bphasevoltage: d["b_ph_vtg"],
            ryphasevoltage: d["ry_ph_vtg"],
            rbphasevoltage: d["rb_ph_vtg"],
            rphasecurrent: d["r_ph_crt"],
            yphasecurrent: d["y_ph_crt"],
            bphasecurrent: d["b_ph_crt"],
            powerfactor: d["power_factor"],
            rphasepowerfactor: d["r_ph_pf"],
            yphasepowerfactor: d["y_ph_pf"],
            bphasepowerfactor: d["b_ph_pf"],
            rphaseactivepower: d["r_ph_act_po"],
            yphaseactivepower: d["y_ph_act_po"],
            bphaseactivepower: d["b_ph_act_po"],
            rphasereactivepower: d["r_ph_react_po"],
            yphasereactivepower: d["y_ph_react_po"],
            bphasereactivepower: d["b_ph_react_po"],
            rphaseapparentpower: d["r_ph_app_po"],
            yphaseapparentpower: d["y_ph_app_po"],
            bphaseapparentpower: d["b_ph_app_po"],
            activeenergy: parseFloat(d["active_energy"]).toFixed(3),
            reactiveenergy: parseFloat(d["reactive_energy"]).toFixed(3),
            apparentenergy: parseFloat(d["apparent_energy"]).toFixed(3),
      
            rvtgphthd: d["r_vtg_ph_thd"],
            yvtgphthd: d["y_vtg_ph_thd"],
            bvtgphthd: d["b_vtg_ph_thd"],
            rcrtphthd: d["r_crt_ph_thd"],
            ycrtphthd: d["y_crt_ph_thd"],
            bcrtphthd: d["b_crt_ph_thd"],
          };
        });
      }
      else if (template === 15) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            "Flow unit": "m³/hr",
            totalizer: d["Totalizer"],
            "Totalizer unit": "m³",
          };
        });
      } else if (template === 16) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            totalizer: d["Totalizer"],
            pumpstatus: d["Pump Status"],
          };
        });
        // .filter((d: any) => d.flow !== undefined && d.totalizer !== undefined);
      } else if (template === 17) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            ph: d["PH"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 18) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 19) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            turb: d["TURB"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 11) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            pump_1: d["pump_1"],
            pump_2: d["pump_2"],
          };
        });
      } else if (template === 21) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            devicestatus: d["device_status"],
          };
        });
      } else if (template === 24) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Inst_flow_rate"],
            flowunit: "m³/hr",
            totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
            totalizerunit: "m³",
          };
        });
      } else if (template === 20) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
          };
        });
      } else if (template === 27) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            Timestamp: formatTimestamp(d.timeStamp),
            Flow: d["Inst_flow"],
            Flowrate: d["Inst_flow_rate"],
            Totalizer: d["Cumu_total"],
            "Forward Totalizer": d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
            "Reverse Totalizer": d["Rev_Cumu_Dec"],
            "Battery Level": d["Batt_lvl"],
            unit: ["m\u00B3"],
          };
        });
      } else if (template === 25) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            energy_generation: d["ene_gener"],
            energy_consumption: d["ene_cons"],
            fuel_consumed: d["fuel_cons"],
            sfc: d["sfc"],
            total_run_hours: d["total_run_hr"],
            active_power: d["act_po"],
            voltage: d["vtg"],
            current: d["crt"],
            power_factor: d["pf"],
            dg_controller: d["dg_ctrl"],
            tank_level: d["tank_level"],
          };
        });
      } else if (template === 26) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            mppt_power: d["mppt_po"],
            mppt_voltage: d["mppt_vtg"],
            mppt_current: d["mppt_crt"],
            ac_current: d["ac_crt"],
            ac_voltage: d["ac_vtg"],
            string_voltage: d["str_vtg"],
            string_current: d["str_crt"],
            inverter_ambient: d["inver_temp_amb"],
            temperature_inverter_internal: d["inver_temp_intr"],
            inverter_status: d["inver_status"],
            today_energy: d["today_energy"],
            total_energy: d["total_energy"],
            active_power: d["act_po"],
            power_factor: d["pf"],
            efficiency: d["effic"],
            cuf: d["cuf"],
            battery_level: d["bat_level"],
            pr_ratio: d["pr_ratio"],
            frequency: d["frequency"],
          };
        });
      }
      else if (template === 28) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
          };
        });
      }
      else if (template === 29) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            Level: parseFloat(d["Level"]).toFixed(3),
            unit: " cm",
          };
        });
      } else if(template === 31){
        _data = resp.map((d:any) =>{
          return{
            logId: d.logId,
            Timestamp: formatTimestamp(d.timeStamp),
            Totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
            Unit: "m³",
            BatteryValue: !isNaN(d.batteryValue)
            ? (((d.batteryValue - 6) / 2) * 100).toFixed(3)
            : "N/A",
            BatteryUnit: "%",
          }
        })
      }
      else if (template === 31) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            Timestamp: formatTimestamp(d.timeStamp),
            Totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
            Unit: "m³",
            BatteryValue: !isNaN(d.batteryValue)
            ? (((d.batteryValue - 6) / 2) * 100).toFixed(3)
            : "N/A",
            BatteryUnit: "%",
          }
        })
      }

      else if (template === 32) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            flow: parseFloat(d["Flow"]),
            flowunit: "m³/hr",
            total: d["Total"],
            totalunit: "m³",
          };
        })
  
      }

      if (role.role === 2) {
        _data = _data.filter((d: any) => {
          // Function to check if any value contains "error" (case insensitive)
          const containsError = (value: any) =>
            typeof value === "string" &&
            value.toLowerCase().includes("error");

          // Check if any value in the object contains "error" (case insensitive)
          return !Object.values(d).some(containsError);
        });

      }
      setApiData(_data);
      console.log('_data', _data)
    } catch (err) {
      console.log(err);
    }
  }

  function formatTimestamp(timestamp: string) {
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }

  // useEffect(() => {
  //   if (startValue["$d"] && endValue["$d"]) {
  //     const startDate: any = new Date(startValue["$d"]);
  //     const endDate: any = new Date(endValue["$d"]);
  //     if (startDate !== "Invalid Date" && endDate !== "Invalid Date") {
  //       getData({ startDate, endDate, deviceId });
  //     }
  //   }
  // }, [startValue, endValue]);

  // async function getData({ startDate, endDate, deviceId }: any) {
  //   try {
  //     const resp: any = await http(
  //       `/device-data?startDate=${startDate}&endDate=${endDate}&deviceId=${deviceId}`
  //     );
  //     setApiData1(resp);
  //     console.log('resp',resp)
  //     let _data = [];
  //     if (template === 5) {
  //       _data = resp.map((d: any, i: any) => {
  //         if (d["networkName"] === "airtel") {
  //           return {
  //             logId: d.logId,
  //             timestamp: formatTimestamp(d.timeStamp),
  //             // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
  //             flow: d["Flow"],
  //             flowunit: "m³/hr",
  //             positivecumulative:
  //               d["Forward Total Flow"] !== "ERROR"
  //                 ? d["Forward Total Flow"]
  //                 : "ERROR",
  //             negativecumulative:
  //               d["Reverse Total Flow"] !== "ERROR"
  //                 ? d["Reverse Total Flow"]
  //                 : "ERROR",
  //             total:
  //               d["Differential Total Flow"] !== "ERROR"
  //                 ? d["Differential Total Flow"]
  //                 : "ERROR",
  //             totalunit: "m³",
  //             rssi: d.rssi,
  //           };
  //         } else {
  //           return {
  //             logId: d.logId,
  //             timestamp: formatTimestamp(d.timeStamp),
  //             // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
  //             flow: calculateFlow(resp, i),
  //             flowunit: "m³/hr",
  //             positivecumulative:
  //               d["Forward Total Flow"] !== "ERROR"
  //                 ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
  //                 : "ERROR",
  //             negativecumulative:
  //               d["Reverse Total Flow"] !== "ERROR"
  //                 ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
  //                 : "ERROR",
  //             total:
  //               d["Differential Total Flow"] !== "ERROR"
  //                 ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
  //                 : "ERROR",
  //             totalunit: "m³",
  //             unit: d["Unit"],
  //             rssi: d.rssi,
  //           };
  //         }
  //       });
  //     } else if (template === 6) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           level: d["Piezo Level"],
  //         };
  //       });
  //     } else if (template === 7) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           do: d["DO"],
  //           ph: d["PH"],
  //           tds: d["TDS"],
  //           temp: d["TEMP"],
  //         };
  //       });
  //     } else if (template === 8 || template === 22 || template === 23) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           x: d["x"],
  //           y: d["y"],
  //           z: d["z"],
  //         };
  //       });
  //     } else if (template === 9) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           totalizer: d["Totalizer"],
  //           unit: "m³",
  //         };
  //       });
  //     } else if (template === 10) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           temperature: d["Temperature"],
  //           humidity: d["Humidity"],
  //         };
  //       });
  //     } else if (template === 12) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           ni: d["NI"],
  //           ph: d["PH"],
  //           tds: d["TDS"],
  //           temp: d["TEMP"],
  //         };
  //       });
  //     } else if (template === 13) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           cl: d["CL"],
  //           ph: d["PH"],
  //           tds: d["TDS"],
  //           temp: d["TEMP"],
  //           turb: d["TURB"],
  //         };
  //       });
  //     } else if (template === 14) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           averagevoltage: d["avg_vtg"],
  //           averagecurrent: d["avg_crt"],
  //           totalactivepower: d["total_act_po"],
  //           totalreactivepower: d["total_react_po"],
  //           totalapparentpower: d["total_app_po"],
  //           frequency: d["frequency"],
  //           rphasevoltage: d["r_ph_vtg"],
  //           yphasevoltage: d["y_ph_vtg"],
  //           ybphasevoltage: d["yb_ph_vtg"],
  //           bphasevoltage: d["b_ph_vtg"],
  //           ryphasevoltage: d["ry_ph_vtg"],
  //           rbphasevoltage: d["rb_ph_vtg"],
  //           rphasecurrent: d["r_ph_crt"],
  //           yphasecurrent: d["y_ph_crt"],
  //           bphasecurrent: d["b_ph_crt"],
  //           powerfactor: d["power_factor"],
  //           rphasepowerfactor: d["r_ph_pf"],
  //           yphasepowerfactor: d["y_ph_pf"],
  //           bphasepowerfactor: d["b_ph_pf"],
  //           rphaseactivepower: d["r_ph_act_po"],
  //           yphaseactivepower: d["y_ph_act_po"],
  //           bphaseactivepower: d["b_ph_act_po"],
  //           rphasereactivepower: d["r_ph_react_po"],
  //           yphasereactivepower: d["y_ph_react_po"],
  //           bphasereactivepower: d["b_ph_react_po"],
  //           rphaseapparentpower: d["r_ph_app_po"],
  //           yphaseapparentpower: d["y_ph_app_po"],
  //           bphaseapparentpower: d["b_ph_app_po"],
  //           activeenergy: d["active_energy"],
  //           reactiveenergy: d["reactive_energy"],
  //           apparentenergy: d["apparent_energy"],

  //           rvtgphthd: d["r_vtg_ph_thd"],
  //           yvtgphthd: d["y_vtg_ph_thd"],
  //           bvtgphthd: d["b_vtg_ph_thd"],
  //           rcrtphthd: d["r_crt_ph_thd"],
  //           ycrtphthd: d["y_crt_ph_thd"],
  //           bcrtphthd: d["b_crt_ph_thd"],
  //         };
  //       });
  //     } else if (template === 15) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           flow: d["Flow"],
  //           totalizer: d["Totalizer"],
  //         };
  //       });
  //     } else if (template === 16) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           flow: d["Flow"],
  //           totalizer: d["Totalizer"],
  //           pumpstatus: d["Pump Status"],
  //         };
  //       });
  //       // .filter((d: any) => d.flow !== undefined && d.totalizer !== undefined);
  //     } else if (template === 17) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           ph: d["PH"],
  //           temp: d["TEMP"],
  //         };
  //       });
  //     } else if (template === 18) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           tds: d["TDS"],
  //           temp: d["TEMP"],
  //         };
  //       });
  //     } else if (template === 19) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           cl: d["CL"],
  //           turb: d["TURB"],
  //           temp: d["TEMP"],
  //         };
  //       });
  //     } else if (template === 11) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           pump_1: d["pump_1"],
  //           pump_2: d["pump_2"],
  //         };
  //       });
  //     } else if (template === 21) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           devicestatus: d["device_status"],
  //         };
  //       });
  //     } else if (template === 24) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           flow: d["Inst_flow_rate"],
  //           flowunit: "m³",
  //           totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
  //           totalizerunit: "m³",
  //         };
  //       });
  //     } else if (template === 20) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           temperature: d["Temperature"],
  //           humidity: d["Humidity"],
  //         };
  //       });
  //     } else if (template === 27) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           Timestamp: formatTimestamp(d.timeStamp),
  //           Flow: d["Inst_flow"],
  //           Flowrate: d["Inst_flow_rate"],
  //           Totalizer: d["Cumu_total"],
  //           "Forward Totalizer": d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
  //           "Reverse Totalizer": d["Rev_Cumu_Dec"],
  //           "Battery Level": d["Batt_lvl"],
  //           unit: ["m\u00B3"],
  //         };
  //       });
  //     } else if (template === 25) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           rssi: d["rssi"],
  //           energy_generation: d["ene_gener"],
  //           energy_consumption: d["ene_cons"],
  //           fuel_consumed: d["fuel_cons"],
  //           sfc: d["sfc"],
  //           total_run_hours: d["total_run_hr"],
  //           active_power: d["act_po"],
  //           voltage: d["vtg"],
  //           current: d["crt"],
  //           power_factor: d["pf"],
  //           dg_controller: d["dg_ctrl"],
  //           tank_level: d["tank_level"],
  //         };
  //       });
  //     } else if (template === 26) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           rssi: d["rssi"],
  //           mppt_power: d["mppt_po"],
  //           mppt_voltage: d["mppt_vtg"],
  //           mppt_current: d["mppt_crt"],
  //           ac_current: d["ac_crt"],
  //           ac_voltage: d["ac_vtg"],
  //           string_voltage: d["str_vtg"],
  //           string_current: d["str_crt"],
  //           inverter_ambient: d["inver_temp_amb"],
  //           temperature_inverter_internal: d["inver_temp_intr"],
  //           inverter_status: d["inver_status"],
  //           today_energy: d["today_energy"],
  //           total_energy: d["total_energy"],
  //           active_power: d["act_po"],
  //           power_factor: d["pf"],
  //           efficiency: d["effic"],
  //           cuf: d["cuf"],
  //           battery_level: d["bat_level"],
  //           pr_ratio: d["pr_ratio"],
  //           frequency: d["frequency"],
  //         };
  //       });
  //     } else if (template === 28) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           temperature: d["Temperature"],
  //         };
  //       });
  //     } else if (template === 29) {
  //       _data = resp.map((d: any) => {
  //         return {
  //           logId: d.logId,
  //           timestamp: formatTimestamp(d.timeStamp),
  //           Level: parseFloat(d["Level"]).toFixed(3),
  //           unit: " m",
  //         };
  //       });
  //     }
  //     _data = _data.filter((d: any) => {
  //       // Check if role !== 1 and filter out error entries
  //       if (role.role !== 1) {
  //         return !Object.values(d).some((val: any) => {
  //           return (
  //             typeof val === "string" &&
  //             (val.toLowerCase() === "error" ||
  //               val.toLowerCase() === "error.error")
  //           );
  //         });
  //       }
  //       return true;
  //     });

  //     setApiData(_data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }



  function calculateFlow(_data: any, i: any) {
    if (i === _data.length - 1) {
      return getSanitizedValue(0);
    }
    const currentData = _data[i];
    const previousData = _data[i + 1];

    if (
      currentData["Differential Total Flow"] === "ERROR" ||
      previousData["Differential Total Flow"] === "ERROR"
    ) {
      return getSanitizedValue(0);
    }

    const currentFlow =
      currentData["Differential Total Flow"] !== "ERROR"
        ? +currentData["Differential Total Flow"]
        : 0;
    const previousFlow =
      previousData["Differential Total Flow"] !== "ERROR"
        ? +previousData["Differential Total Flow"]
        : 0;
    const timeDiff =
      +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
    let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
    if (flow < 0) {
      flow = 0;
    }
    const v = getSanitizedValue(flow * 0.001);
    if (isNaN(v)) {
      return 0;
    }

    return v;
  }

  function getSanitizedValue(value: any) {
    if (typeof value === "number") {
      return value.toFixed(3);
    } else {
      return value;
    }
  }

  let filterData1: any;
  if (role.role === 1) {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      ...row,
    }));
  } else {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      // timestamp: row.timestamp,
      // Flow: row.flow,
      // "flow unit": row.flowunit,
      // total: row.positivecumulative,
      // "total unit": row.totalunit,
      ...row,
    }));
  }
  filterData1.sort((a: { timestamp: string | number | Date; }, b: { timestamp: string | number | Date; }) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());


  // let filterData: any;
  // if (role.role === 1) {
  //   filterData = apiData.map((row: any) => ({
  //     "#": (
  //       <ProtectedComponent>
  //         <Checkbox
  //           color="primary"
  //           checked={selected.indexOf(row.logId) !== -1}
  //           onChange={(event) => handleClick(row.logId)}
  //         />
  //       </ProtectedComponent>
  //     ),
  //     ...row,
  //   }));
  // } else {
  //   filterData = apiData

  //     .map((row: any) => ({
  //       "#": (
  //         <ProtectedComponent>
  //           <Checkbox
  //             color="primary"
  //             checked={selected.indexOf(row.logId) !== -1}
  //             onChange={(event) => handleClick(row.logId)}
  //           />
  //         </ProtectedComponent>
  //       ),
  //       ...row,
  //     }));
  // }

  const handleDataLogDelete = async () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete these logs?"
    );

    if (shouldDelete) {
      try {
        const resp: any = await http(`/device-data-log`, "DELETE", selected);
        if (startValue["$d"] && endValue["$d"]) {
          const startDate: any = new Date(startValue["$d"]);
          const endDate: any = new Date(endValue["$d"]);
          if (startDate !== "Invalid Date" && endDate !== "Invalid Date") {
            getData({ startDate, endDate, deviceId });
          }
        }
        toast(resp.message);
        setSelected([]);
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  const handleClickAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(apiData.map((row: any) => row.logId));
    }
  };

  const toggleFilterList = () => {
    setFilterTable(!filterTable);
  };

  const handleSelectedDataChange = (selectedData: string[]) => {
    setHeaders(selectedData);
    setOptionColumns(selectedData);
  };

  const handleExportReport = () => {
    // const device = devices.devices.find(
    //   (device: any) => device.id === deviceId
    // );
    const startDate: any = new Date(startValue["$d"]);
    const endDate: any = new Date(endValue["$d"]);
    const startDateFormatted = startDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    // Convert end date to desired format
    const endDateFormatted = endDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    let tName = "";
    switch (device.template) {
      case 5:
      case 6:
      case 9:
      case 15:
      case 16:
      case 24:
      case 31:
        tName += "Water Consumption Summary";
        break;
      case 7:
      case 12:
      case 13:
      case 17:
      case 18:
      case 19:
        tName += "Water Quality Summary";
        break;
      case 8:
      case 22:
      case 23:
        tName += "Vibration Summary";
        break;
      case 10:
      case 20:
        tName += "Temperature & Humadity Summary";
        break;
      case 11:
      case 21:
        tName += "Automation Summary";
        break;
      case 14:
        tName += "Energy Summary";
        break;
      case 27:
        tName += "Atlanta Summary";
        break;
      case 26:
        tName += "Solar Summary";
        break;
      case 25:
        tName += "DG Summary";
        break;
      case 28:
        tName += "Temperature Summary";
        break;
      case 29:
        tName += "Level Summary";
        break
      case 32:
        tName += "Water Summary";
        break
      default:
        return;
    }

    if (template === 9) {
      reportData1(
        apiData,
        ["timestamp", "totalizer", "unit"],
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted
      );
    }
    else {
      
      reportData(
        device?.template,
        apiData1,
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted,
        device
      );
    }
  };

  const handleExportBarChart = async (exportOption: string) => {
    // const device = devices.devices.find(
    //   (device: any) => device.id === deviceId
    // );
    switch (exportOption) {
      case "Download as PDF":
        handleExportReport();
        break;
      case "Download as CSV":
        if (template === 14) {
          generateCSV(
            optionColumns.length === 0 ? energy : optionColumns,
            apiData
          );
        } else if (
          template === 5 ||
          template === 26 ||
          template === 25 ||
          template === 9
        ) {
          generateCSV(headers, apiData);
        } else {
          generateCSV1(apiData1, device.template, device);
        }

        break;
      case "Download as Report":
        let column = ["Date", "FirstTimeStamp", "LastTimeStamp", "Consumption"];
        let date = apiData[0].timestamp;
        let lastTimeStamp = apiData[0].timestamp;
        let firstTimeStamp = apiData[apiData.length - 1].timestamp;
        let consumption =
          apiData[apiData.length - 1].totalizer - apiData[0].totalizer;

        if (consumption < 0) {
          consumption = consumption * -1;
        }

        let resultObject = {
          Date: date?.slice(0, 10),
          FirstTimeStamp: firstTimeStamp,
          LastTimeStamp: lastTimeStamp,
          Consumption: consumption,
        };
        reportData1(
          [resultObject],
          column,
          device.deviceName,
          device?.Customer?.name || "N/A",
          "Totalizer Summary",
          formatIndianTime(startValue["$d"]) +
          " - " +
          formatIndianTime(endValue["$d"])
        );
        break;
      default:
        return; // Unknown export option
    }
  };

  return (

    <div className="px-4">
      <div>
        <p className="text-xl font-semibold text-slate-500">{deviceName}</p>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex">
          <div className="mr-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Start date & time"
                  value={startValue}
                  onChange={(newStartValue) => setStartValue(newStartValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="End date & time"
                  value={endValue}
                  onChange={(newEndValue) => setEndValue(newEndValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ProtectedComponent>
            {selected.length > 0 && (
              <IconButton onClick={handleDataLogDelete}>
                <DeleteIcon />
              </IconButton>
            )}
            {apiData.length > 0 && (
              <IconButton onClick={handleClickAll}>
                <Checkbox checked={selected.length > 0} />
              </IconButton>
            )}
          </ProtectedComponent>
          {(template === 5 || template === 14) && (
            <IconButton onClick={toggleFilterList}>
              <FilterListIcon />
            </IconButton>
          )}

          <LongMenu
            options={[
              "Download as PDF",
              "Download as CSV",
              template === 9 ? "Download as Report" : "",
            ]}
            onOptionClick={handleExportBarChart}
            id={1}
          />

        </div>

      </div>
      <div className="mt-10">
        {selectedOption === "chart" && (
          <div className="graph-list">
            <p className="border-b border-slate-300 pb-2 text-lg text-blue-500">
              Device Name
            </p>
            <div className="flex justify-center mt-4">
              {chartData ? (
                <div>
                  <CustomLineChart {...chartData} height={300} width={500} />
                </div>
              ) : (
                <p>No Data Available</p>
              )}
            </div>
          </div>
        )}
        {selectedOption === "table" && (
          <div className="tabular-data ">
            <TabularComponents rows={filterData1} headers={headers} sortableColumns={["TIMESTAMP"]} />
          </div>
        )}
      </div>
      <Dialog
        open={filterTable}
        onClose={() => {
          setFilterTable(false);
        }}
      >
        <CheckBoxFilter
          data={template === 5 ? header : energy}
          onSelectedDataChange={handleSelectedDataChange}
        />
      </Dialog>
    </div>
  );
};

export default Data;
