import { Dialog } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmModalProps {
  title: string;
  message: string;
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isVisible,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog open={isVisible} onClose={onCancel}>
      <div className="modal-container bg-white p-8 max-w-full rounded-md overflow-y-auto w-full">
        <div className="modal-header flex justify-between items-center mb-4">
          <h2 className="modal-title text-lg font-semibold">{title}</h2>
          <button className="modal-close text-gray-600" onClick={onCancel}>
            <CloseIcon />
          </button>
        </div>
        <div className="modal-text mb-4">{message}</div>
        <div className="modal-buttons flex justify-end">
          <button className="modal-button mr-5 text-red-500" onClick={onConfirm}>
            Confirm
          </button>
          <button className="modal-button secondary" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};
