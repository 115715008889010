// import React from 'react';

// interface MobileTowerIconProps {
//   levels: number;
//   time: string | null;
// }

// const MobileTowerIcon: React.FC<MobileTowerIconProps> = ({ levels, time }) => {
//   if (time === undefined || time === null) {
//     return (
//       <div className="relative flex items-end space-x-1 mr-2 mb-1">
//         {Array.from({ length: 5 }).map((_, index) => (
//           <div
//             key={index}
//             style={{
//               height:
//                 index === 0
//                   ? "5px"
//                   : index === 1
//                   ? "8px"
//                   : index === 2
//                   ? "11px"
//                   : index === 3
//                   ? "14px"
//                   : "17px",
//               width: "0.25rem",
//               backgroundColor: "#9ca3af",
//             }}
//           ></div>
//         ))}
//       </div>
//     );
//   }

//   const currentTime = new Date();
//   const providedTime = new Date(time);
//   const sixHoursAgo = new Date(currentTime);
//   sixHoursAgo.setHours(currentTime.getHours() - 6);
//   const status = providedTime >= sixHoursAgo && providedTime <= currentTime;

//   let greenBars = 0;
//   let color = "";
//   if (status) {
//     if (levels >= 24) {
//       greenBars = 5;
//       color = "#027A48";
//     } else if (levels >= 18 && levels < 24) {
//       greenBars = 4;
//       color = "#0778AA";
//     } else if (levels >= 12 && levels < 18) {
//       greenBars = 3;
//       color = "#FF9900";
//     } else if (levels >= 6 && levels < 12) {
//       greenBars = 2;
//       color = "#D92D20";
//     } else if (levels >= 1 && levels < 6) {
//       greenBars = 1;
//       color = "#D92D20";
//     } else if (levels <= 0) {
//       greenBars = 0;
//     }
//   } else {
//     greenBars = 0;
//   }

//   return (
//     <div className="relative flex items-end space-x-1 mr-2 mb-1">
//       {Array.from({ length: 5 }).map((_, index) => (
//         <div
//           key={index}
//           style={{
//             height:
//               index === 0
//                 ? "5px"
//                 : index === 1
//                 ? "8px"
//                 : index === 2
//                 ? "11px"
//                 : index === 3
//                 ? "14px"
//                 : "17px",
//             width: "0.25rem",
//             backgroundColor: `${index < greenBars ? color : "#9ca3af"}`,
//           }}
//         ></div>
//       ))}
//     </div>
//   );
// };

// export default MobileTowerIcon;


import React from 'react';

interface MobileTowerIconProps {
  levels: number;
  time: string | null;
}

const MobileTowerIcon: React.FC<MobileTowerIconProps> = ({ levels, time }) => {
  if (time === undefined || time === null) {
    return (
      <div className="relative flex items-end space-x-1 mr-2 mb-1">
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            style={{
              height:
                index === 0
                  ? "5px"
                  : index === 1
                  ? "8px"
                  : index === 2
                  ? "11px"
                  : index === 3
                  ? "14px"
                  : "17px",
              width: "0.25rem",
              backgroundColor: "#9ca3af",
            }}
          ></div>
        ))}
      </div>
    );
  }

  const currentTime = new Date();
  const providedTime = new Date(time);
  const thirtyMinutesAgo = new Date(currentTime);
  thirtyMinutesAgo.setMinutes(currentTime.getMinutes() - 30);
  const status = providedTime >= thirtyMinutesAgo && providedTime <= currentTime;

  let greenBars = 0;
  let color = "";
  if (status) {
    if (levels >= 24 || levels<0) {
      greenBars = 5;
      color = "#027A48";
    } else if (levels >= 18 && levels < 24) {
      greenBars = 4;
      color = "#0778AA";
    } else if (levels >= 12 && levels < 18) {
      greenBars = 3;
      color = "#FF9900";
    } else if (levels >= 6 && levels < 12) {
      greenBars = 2;
      color = "#D92D20";
    } else if (levels >= 1 && levels < 6) {
      greenBars = 1;
      color = "#D92D20";
    } else if (levels <= 0) {
      greenBars = 0;
    }
  } else {
    greenBars = 0;
  }

  return (
    <div className="relative flex items-end space-x-1 mr-2 mb-1">
      {Array.from({ length: 5 }).map((_, index) => (
        <div
          key={index}
          style={{
            height:
              index === 0
                ? "5px"
                : index === 1
                ? "8px"
                : index === 2
                ? "11px"
                : index === 3
                ? "14px"
                : "17px",
            width: "0.25rem",
            backgroundColor: `${index < greenBars ? color : "#9ca3af"}`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default MobileTowerIcon;