import "./index.css";
import { HashRouter , Route, Routes } from "react-router-dom";
import Login from "./modules/login/Login";
import Homepage from "./modules/homepage/Homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeviceProvider } from "./app-context/device-context/DeviceState";
import { routes } from "./dynamicRoute/DynamicRoute";
import { CustomerProvider } from "./app-context/customer-context/CustomerState";
import { UserProvider } from "./app-context/auth-context/AuthState";
import ProtectedRoute from "./utils/protected-route/ProtectedRoute";
import { TagProvider } from "./app-context/tags-context/TagState";
import { TemplateProvider } from "./app-context/template-context/TemplateState";
import { TagGroupProvider } from "./app-context/taggroup-context/TagGroupState";
import { UserOnBoardProvider } from "./app-context/user-context/UserState";
import ProtectedRouteRole from "./utils/protected-route/ProtectedRouteRole";
import Customers from "./modules/admin/customer/Customers";
import { AlertProvider } from "./app-context/alert-context/AlertState";


function App() {



  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <UserProvider>
        <DeviceProvider>
          <CustomerProvider>
            <TagProvider>
              <TemplateProvider>
                <TagGroupProvider>
                  <UserOnBoardProvider>
                    <AlertProvider>
                    <HashRouter>
                      <Routes>
                        <Route path="/login" index={true} element={<Login />} />
                        {/* <Route
                          path="/forgot-password"
                          element={<Forgotpassword />}
                        /> */}
                        {/* <Route
                            path="/set-password"
                            element={<SetPassword  />}
                          /> */}
                        {/* <Route element={<ProtectedSetPasswordRoute />}>
                          <Route
                            path="/set-password"
                            element={<SetPassword />}
                          />
                        </Route> */}

                        <Route path="/" element={<ProtectedRoute />}>
                          {/* <Route path="/register" element={<Register />} />
                          <Route
                            path="/forgot-password"
                            element={<Forgotpassword setIsOpen={setIsOpen} />}
                          />
                          <Route
                            path="/verification"
                            element={<Verification setIsOpen={setIsOpen} />}
                          />
                          <Route
                            path="/create-password"
                            element={<Createpassword setIsOpen={setIsOpen} />}
                          /> */}
                          <Route
                            path="/customer"
                            element={<ProtectedRouteRole />}
                          >
                            <Route path="" element={<Homepage />}>
                              <Route path="" element={<Customers />} />
                            </Route>
                          </Route>
                          <Route path="/" element={<Homepage />}>
                            {routes.map((route, index) => (
                              <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                              />
                            ))}
                          </Route>
                          
                        </Route>
                      </Routes>
                    </HashRouter>
                    </AlertProvider>
                  </UserOnBoardProvider>
                </TagGroupProvider>
              </TemplateProvider>
            </TagProvider>
          </CustomerProvider>
        </DeviceProvider>
      </UserProvider>
    </>
  );
}

export default App;
