import { ApiResponseForCreateTagGroup, PaginatedTagGroupWithTagTemplate } from "../../types/TagGroup";

export interface TagGroupState {
  tagsGroups: PaginatedTagGroupWithTagTemplate;
}

export type TagGroupAction =
  | { type: "SET_TAG_GROUP"; payload: PaginatedTagGroupWithTagTemplate }
  | { type: "SET_TAG_GROUP_WITH_PAGE"; payload: PaginatedTagGroupWithTagTemplate }
  | { type: "ADD_TAG_GROUP"; payload: { totalitems: number; taggroup: ApiResponseForCreateTagGroup } }
  | { type: "UPDATE_TAG_GROUP"; payload: { id: number; tagname: string, templatename: string }; }
  | { type: "SET_TAG_GROUP_TEMPLATE"; payload: PaginatedTagGroupWithTagTemplate }




export default function CustomerReducer(
  state: TagGroupState,
  action: TagGroupAction
): TagGroupState {
  const { type, payload } = action;

  switch (type) {
    case "SET_TAG_GROUP":
      return { ...state, tagsGroups: payload };
    case "SET_TAG_GROUP_TEMPLATE":
      return { ...state, tagsGroups: payload }
    case "SET_TAG_GROUP_WITH_PAGE":
      return { ...state, tagsGroups: payload }
    case "ADD_TAG_GROUP":
      const newTagGroupData = payload.taggroup.tagGroupData;
      const newTagGroups = Array.isArray(newTagGroupData) ? newTagGroupData : [newTagGroupData];

      return {
        ...state,
        tagsGroups: {
          ...state.tagsGroups,
          tagGroups: [...newTagGroups, ...state.tagsGroups.tagGroups],
          totalItems: state.tagsGroups.totalItems + 1
        },
      };

    case "UPDATE_TAG_GROUP":
      const { id, tagname, templatename } = payload;
      const updatedTagGroups = state.tagsGroups.tagGroups.map(tagGroup => {
        if (tagGroup.id === id) {
          // Update nested properties inside tag and template objects
          return {
            ...tagGroup,
            tag: { ...tagGroup.tag, tagname },
            template: { ...tagGroup.template, templatename },
          };
        }
        return tagGroup;
      });

      return {
        ...state,
        tagsGroups: {
          ...state.tagsGroups,
          tagGroups: updatedTagGroups,
        },
      };



    default:
      return state;
  }
}










// import { ApiResponseForGetAllTagsGroupWithTemplateTag } from "../../types/TagGroup";

// export interface TagGroupState {
//   tagGroups: ApiResponseForGetAllTagsGroupWithTemplateTag;
// }

// export type TagGroupAction =
//   | { type: "SET_TAG_GROUP"; payload: ApiResponseForGetAllTagsGroupWithTemplateTag }

// export default function CustomerReducer(
//   state: TagGroupState,
//   action: TagGroupAction
// ): TagGroupState {
//   const { type, payload } = action;
// console.log(payload);

//   switch (type) {
//     case "SET_TAG_GROUP":
//       return { ...state, tagGroups: payload }; // Access the tagsGroup property
//     default:
//       return state;
//   }
// }

