import ProtectedComponent from '../../utils/protected-route/ProtectedComponent'
import AdminDashboard1 from './AdminDashboard1'
import CustomerDashboard from './CustomerDashboard'
import Overviewpage from './Overviewpage'
const DashBoard = () => {
  return (
    <>
      <ProtectedComponent>
        <AdminDashboard1 />
      </ProtectedComponent>
      <ProtectedComponent roleids={[2]}>
        {/* <DashbaordWater /> */}
        {/* <CustomerDashboard /> */}
        <Overviewpage />
      </ProtectedComponent>
    </>
  )
}

export default DashBoard