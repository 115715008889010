import  { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [token] = useState(window.localStorage.getItem("token"))

  useEffect(() => {
    // const waitAndCall = () => {}
      if (!token) {
        // setTimeout(() => {
          window.localStorage.removeItem("token")
          navigate("/");
        // }, 1000000);
      // } else {
      //   navigate("/login");
      // }
    };
    // Execute the waitAndCall function when the component mounts
    // waitAndCall();
  }, [token, navigate]);
  const waitTillLoaded = () => {
    if (!token) {
      window.localStorage.removeItem("token")
      navigate("/login");
    } else {
      return (
        <>
          <Outlet />
        </>
      );
    }
  };

  return <>{waitTillLoaded()}</>;
};

export default ProtectedRoute;





