import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg";

interface LineData {
  name?: string;
  color: string;
  showDots?: number;
  data: { timestamp: string; value: number }[];
  showHoverTooltip?: boolean;
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  yAxisName?: string;
  stroke?: Boolean;
  xAxisName?: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, yAxisName, xAxisName, stroke }) => {
  const series = data.map((line) => ({
    name: line.name || "",
    data: line.data.map((point) => ({
      x: new Date(point.timestamp).getTime(),
      y: point.value
    })),
    color: line.color,
    showDots: line.showDots,
    postfix: line.postfix,
  }));

  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      min: twentyFourHoursAgo.getTime(),
      max: now.getTime(),
      title: {
        text: xAxisName,
      },
      labels: {
        datetimeUTC: false,
        format: 'HH:mm',
      },
      tickAmount: 24,
    },
    yaxis: [
      {
        title: {
          text: "Temperature (°C)",
        },
        labels: {
          formatter: function (value: any) {
            return parseFloat(value).toFixed(3);
          }
        }
      },
      {
        opposite: true,
        title: {
          text: "Humidity (%)",
        },
        labels: {
          formatter: function (value: any) {
            return parseFloat(value).toFixed(3);
          }
        }
      }
    ],
    tooltip: {
      x: {
        formatter: function(val: any) {
          return new Date(val).toLocaleString();
        }
      },
      y: {
        formatter: function (value, { seriesIndex }) {
          const postfix = data[seriesIndex].postfix || "";
          return value.toFixed(2) + postfix;
        },
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5,
      },
    },
    colors: series.map((line) => line.color),
    stroke: {
      width: 2,
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 8,
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <div className="mt-2">
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={360}
      key={JSON.stringify(data)}
    />
   </div> 
  );
};

export default LineChart;