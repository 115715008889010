import { http } from "../../helper/http";
import { ApiResponse } from "../../types/UserTypes";
import { User } from "../../types/UserTypes";



// interface ApiResponse {
//   token: string;
//   user: User;
// }

const baseUrl = process.env.REACT_APP_BASE_URL;


interface SetUserAction {
  type: "SET_USERS";
  payload: ApiResponse;
}

export type UserAction = SetUserAction;
export const login = ({
  dispatch,
  token,
  user,
}: {
  dispatch: React.Dispatch<UserAction>;
  token: string;
  user: User;
}) => {
  window.localStorage.setItem("token", token);
  // if(!window.localStorage.getItem("token")==undefined){
    window.location.href = "/";

  // } else {
  // window.localStorage.removeItem("token")
  // }
  // const payload: ApiResponse = { token, user };

  // dispatch({
  //   type: "SET_USERS",
  //   payload: payload,
  // });
};

export const logout = (dispatch: React.Dispatch<UserAction>) => {
  window.localStorage.removeItem("token");
  window.location.href = "/#/login";
};

export async function setUser(dispatch: React.Dispatch<UserAction>) {
  try {
    const token = window.localStorage.getItem("token");
    if (!token) {
      window.localStorage.removeItem("token");
      logout(dispatch);
    }
    const user = (await http(baseUrl + "/current-user")) as ApiResponse;
    
    // const payload: ApiResponse = { token: "your-token-value"};
    dispatch({
      type: "SET_USERS",
      payload: user,
    });
  } catch (err) {
    window.localStorage.removeItem("token");
    logout(dispatch);
  }
}












