import { useEffect, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import Energy_generator from "../../../images/electric-generator 1.png";
import Energy_Consumption from "../../../images/energy_consumption.png";
import Fuel_Consumed from "../../../images/fuelconsumed.png";
import total_run_hours from "../../../images/total_run_hours.png";
import SFC from "../../../images/sfc.png";
import active_power from "../../../images/activepower.png";
import voltage from "../../../images/voltage.png";
import current from "../../../images/flash.png";
import {
  formatTimestamp,
  getIndianCurrentTime,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import LongMenu from "../../../utils/longMenu/LongMenu";
import LineChart from "../../../components/apex-chart/LineChart";
import { http } from "../../../helper/http";
import ReactSpeedometer from "react-d3-speedometer";
import BarChart2Component from "../../../components/apex-chart/DoubleLineBarChart";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardDg = ({ selectedDevice, devices }: any) => {
  const [checked, setChecked] = useState(false);
  const [apiData, setApiData] = useState<any>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}`
      );


      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            energy_generation: d["ene_gener"],
            energy_consumption: d["ene_cons"],
            fuel_consumed: d["fuel_cons"],
            sfc: d["sfc"] || 0,
            total_run_hours: d["total_run_hr"],
            active_power: d["act_po"],
            voltage: d["vtg"],
            current: d["crt"],
            power_factor: d["pf"],
            dg_controller: d["dg_ctrl"],
            tank_level: d["tank_level"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              val === undefined ||
              (typeof val === "string" && val.toLowerCase() === "error")
          );
        });
      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
      setChecked(+response[0].dg_ctrl === 1 ? true : false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const handleChange = async () => {
    setChecked((prev) => !prev);
    try {
      let time = getIndianCurrentTime();

      // const newData = {
      //   timestamp: time,
      //   rssi: dashboardSts.rssi,
      //   energy_generation: dashboardSts.energy_generation,
      //   energy_consumption: dashboardSts.energy_consumption,
      //   fuel_consumed: dashboardSts.fuel_consumed,
      //   sfc: dashboardSts.sfc,
      //   total_run_hours: dashboardSts.total_run_hours,
      //   active_power: dashboardSts.active_power,
      //   voltage: dashboardSts.voltage,
      //   current: dashboardSts.current,
      //   power_factor: dashboardSts.power_factor,
      //   dg_controller: checked ? 0 : 1,
      //   tank_level: dashboardSts.tank_level,
      // };

      // if (checked) {
      //   setApiData((prevData: any) => [newData, ...prevData]);
      // } else {
      //   setApiData((prevData: any) => [newData, ...prevData]);
      // }

      // const message = { "Pump Status": checked ? 0 : 1, Totalizer: 0, Flow: 0 };
      const message = {
        dg_ctrl: checked ? 0 : 1,
      };

      const resp: any = await http("/mqtt-publish?start=1", "POST", {
        deviceId: devices.deviceId,
        message: JSON.stringify(message),
      });
      toast.success(resp.message);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const activepower =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, active_power }: any) => ({
          timestamp,
          value: active_power,
        }))
      : [];

  const voltagedata24hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, voltage }: any) => ({
          timestamp,
          value: voltage,
        }))
      : [];
  const current24hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, current }: any) => ({
          timestamp,
          value: current,
        }))
      : [];
  const powerfactor24hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, power_factor }: any) => ({
          timestamp,
          value: power_factor,
        }))
      : [];
  const powergraph: any = [
    {
      name: "Active Power",
      color: "#4287f5",
      data: activepower.reverse(),
    },
    {
      name: "Voltage",
      color: "#19a645",
      data: voltagedata24hour.reverse(),
      postfix: " V",
    },
    {
      name: "Current",
      color: "#c92d0e",
      data: current24hour.reverse(),
      postfix: " A",
    },

    {
      name: "Power Factor",
      color: "#f0ec13",
      data: powerfactor24hour.reverse(),
    },
  ];

  const energyconsumption_energygenerationpdf =
    last24HoursData.length > 0
      ? last24HoursData.map(
          ({ timestamp, energy_generation, energy_consumption }: any) => ({
            timestamp,
            energy_consumption: energy_consumption,
            energy_generation: energy_generation,
          })
        )
      : [];

  const energy_consumption_energy_generation = last24HoursData
    .map(({ timestamp, energy_generation, energy_consumption }: any) => {
      return {
        timestamp,
        value1: energy_generation,
        value2: energy_consumption,
      };
    })
    .reverse();

  const linechartpdf = last24HoursData.map(
    ({ timestamp, power_factor, voltage, current, active_power }: any) => {
      return {
        timestamp,
        active_power: active_power,
        voltage: voltage,
        current: current,
        power_factor: power_factor,
      };
    }
  );

  const handleExportBarChart = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        // generateCSV1(lineChartData);
        break;

      case "Download as PDF":
        if (energyconsumption_energygenerationpdf && chartRef) {
          reportDataWithGraph(
            chartRef,
            energyconsumption_energygenerationpdf,
            devices?.deviceName,
            devices?.Customer?.name,
            "DG Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const handleExportBarChart1 = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        // generateCSV1(lineChartData);
        break;

      case "Download as PDF":
        if (linechartpdf && chartRef1) {
          reportDataWithGraph(
            chartRef1,
            linechartpdf,
            devices?.deviceName,
            devices?.Customer?.name,
            "DG Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>

      <div className="border border-gray-400 p-[5px] mb-[5px] grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-3 xl:grid-cols-5 2xl:gap-7.5 rounded-md">
        <CardTwo
          title="Energy Generation"
          amount={`${dashboardSts.energy_generation || 0} kWh`}
          icon={Energy_generator}
        />
        <CardTwo
          title="Energy Consumption"
          amount={`${dashboardSts.energy_consumption || 0} kWh`}
          icon={Energy_Consumption}
        />
        <CardTwo
          title="Fuel Consumed"
          amount={`${dashboardSts.fuel_consumed || 0} Lt`}
          icon={Fuel_Consumed}
        />
        <CardTwo
          title="SFC"
          amount={`${dashboardSts.sfc || 0} Lt/KWh`}
          icon={SFC}
        />
        <CardTwo
          title="Total Run Hours"
          amount={`${dashboardSts.total_run_hours || 0} Hours`}
          icon={total_run_hours}
        />
        <CardTwo
          title="Active Power"
          amount={`${dashboardSts.active_power || 0} kW`}
          icon={active_power}
        />
        <CardTwo
          title="Voltage"
          amount={`${dashboardSts.voltage || 0} V`}
          icon={voltage}
        />
        <CardTwo
          title="Current"
          amount={`${dashboardSts.current || 0} A`}
          icon={current}
        />
        <CardTwo
          title="Power Factor"
          amount={`${dashboardSts.power_factor || 0} `}
          icon={current}
        />
        <div className="rounded-lg border border-stroke p-3 bg-white ">
          <h2 className="font-medium" style={{ whiteSpace: "nowrap" }}>
            DG Controller
          </h2>

          <div className="mt-2 flex items-end justify-between">
            <div>
              <h4
                className="text-title-md text-3xl"
                style={{ color: checked ? "green" : "red" }}
              >
                {checked ? "ON" : "OFF"}{" "}
                {/* Display text indicating switch state */}
              </h4>
            </div>
            <Switch
              className="ml-20"
              checked={checked}
              onChange={handleChange}
              color="primary"
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "green",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "green",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "red",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-1">
        <div className="border border-gray-400 p-[20px] w-3/2  rounded-md">
          <p className="mb-[20px] font-medium">Tank Level</p>
          <ReactSpeedometer
            maxValue={100}
            value={dashboardSts.tank_level}
            needleColor="grey"
            needleHeightRatio={0.5}
            segments={3}
            segmentColors={["limegreen", "gold", "tomato"]}
            currentValueText="${value}%"
            valueTextFontSize={"37px"}
            paddingVertical={40}
          />
        </div>
        <div className="border border-gray-400 p-[20px] w-full  rounded-md">
          <div className="relative">
            <p className="">Daily Energy data summary</p>
            <p className="mb-[10px]">
              {" "}
              (between{" "}
              {last24HoursData.length > 0
                ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " to " +
                  last24HoursData[0].timestamp
                : " "}
              )
            </p>
            <div className="absolute top-0 right-0">
              <LongMenu
                options={[
                  "Download as CSV",
                  "Download as PDF",
                ]}
                onOptionClick={handleExportBarChart}
                id={1}
              />
            </div>
            <div ref={chartRef}>
              <BarChart2Component data={energy_consumption_energy_generation} />
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-[5px] border border-gray-400 p-[20px] w-full rounded-md">
        <div className="relative">
          <p className="mb-[20px]">
            Instantaneous data variation with time (between{" "}
            {last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                " to " +
                last24HoursData[0].timestamp
              : " "}
            )
          </p>
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart1}
              id={1}
            />
          </div>
          <div ref={chartRef1}>
            <LineChart
              stroke={true}
              yAxisName="Data"
              xAxisName="Time"
              data={powergraph}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDg;
