import { Device, PaginatedDevice } from "../../types/DeviceTypes";
import { http } from "../../helper/http";

interface SetDevicesAction {
  type: "SET_DEVICES";
  payload: PaginatedDevice;
}

interface SetDevicesPageAction {
  type: "SET_DEVICES_WITH_PAGE";
  payload: PaginatedDevice;
}

interface UpdateDevicesStatusAction {
  type: "UPDATE_DEVICES_STATUS";
  payload: { id: number; status: boolean };
}

interface AddDevicesAction {
  type: "ADD_DEVICES";
  payload: { devices: Device; addTotalItems: number };
}

interface UpdateDeviceAction {
  type: "UPDATE_DEVICES";
  payload: { id: number; devices: Device };
}

export type DeviceAction =
  | SetDevicesAction
  | UpdateDevicesStatusAction
  | AddDevicesAction
  | SetDevicesPageAction
  | UpdateDeviceAction;

interface ApiResponse {
  message: string;
  devices: PaginatedDevice;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setDevice(
  dispatch: React.Dispatch<DeviceAction>,
  payload: number
) {
  try {
    const page = payload ?? 1;
    const response = (await http(
      baseUrl + `/device?page=${page}`
    )) as ApiResponse;
    console.log('baseUrl',response)
    const responseData = response;
    dispatch({
      type: "SET_DEVICES",
      payload: responseData.devices,
    });
  } catch (err) {
    console.error(err);
  }
}

export function updateDeviceStatus(
  dispatch: React.Dispatch<DeviceAction>,
  payload: { id: number; status: boolean }
) {
  dispatch({
    type: "UPDATE_DEVICES_STATUS",
    payload,
  });
}

export function addNewDevices(
  dispatch: React.Dispatch<DeviceAction>,
  payload: { devices: Device; addTotalItems: number }
) {
  dispatch({
    type: "ADD_DEVICES",
    payload,
  });
}

export function getDevicePageClick(
  dispatch: React.Dispatch<DeviceAction>,
  payload: any
) {
  dispatch({
    type: "SET_DEVICES_WITH_PAGE",
    payload,
  });
}

export function updateDevices(
  dispatch: React.Dispatch<DeviceAction>,
  payload: { id: number; devices: Device }
) {
  dispatch({
    type: "UPDATE_DEVICES",
    payload,
  });
}
