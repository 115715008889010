import React from "react";

interface CardOneProps {
  title?: string;
  amount?: string;
  temperature?: string;
  humidity?: string;
  icon?: any;
  title2?: string;
  color?: string;
  background?: string;
  style?: any;
  iconbar?: boolean;
}

const CardTwo: React.FC<CardOneProps> = ({
  title,
  amount,
  temperature,
  humidity,
  icon,
  title2,
  background,
  style,
  iconbar,
}) => {
  console.log('amount',amount);
  console.log('temperature',temperature);
  console.log('humidity',humidity);
  return (
    <div
      className="rounded-lg border border-stroke p-3  bg-white"
      style={{ background, ...style, overflow: "hidden" }}
    >
      <div className="flex">
        <div className={`${iconbar? "w-3/4": "w-full"}`}>
          <div className="mt-2 items-end justify-between">
            <div>
              {/* <h2 className="font-bold">{title}</h2> */}
              <h2 className="text-[16px]" style={{ whiteSpace: "nowrap" }}>{title}</h2>

              <h4 className="text-title-md font-bold text-black text-[24px] py-1">
                {amount || temperature || humidity}
              </h4>
            </div>
          </div>
          <div>{title2 && <p>{title2}</p>}</div>
        </div>
        {iconbar && (
        <div className="w-1/4 mt-4 flex justify-center items-center rounded-sm">
          {icon && <img src={icon} alt="Icon"  />}
        </div>
        )}
      </div>
    </div>
  );
};

export default CardTwo;
