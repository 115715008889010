import { useEffect, useRef, useState } from "react";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import { http } from "../../helper/http";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import LineChart from "../../components/apex-chart/LineChart";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

import LongMenu from "../../utils/longMenu/LongMenu";
import { Button, ButtonGroup } from "@material-ui/core";
const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardVibration2 = ({ devices, selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const [valueAnalysis, setvalueAnalysis] = useState(0);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}`
      );

      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            x: d["x"],
            y: d["y"],
            z: d["z"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const x =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, x }: any) => ({
          timestamp,
          value: x,
        }))
      : [];

  const y =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, y }: any) => ({
          timestamp,
          value: y,
        }))
      : [];

  const z =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, z }: any) => ({
          timestamp,
          value: z,
        }))
      : [];

  const xlinechart: any = [
    {
      name: "X",
      color: "#25c448",
      showDots: true,
      data: x.reverse(),
    },
  ];
  const ylinechart: any = [
    {
      name: "Y",
      color: "#e02f2f",
      showDots: false,
      showHoverTooltip: false,
      data: y.reverse(),
    },
  ];
  const zlinechart: any = [
    {
      name: "Z",
      color: "#4452cf",
      showDots: false,
      showHoverTooltip: false,
      data: z.reverse(),
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    let chartData: any;
    if (valueAnalysis === 0) {
      chartData = x.map(({ timestamp, value }) => {
        return {
          TIMESTAMP: timestamp,
          X: value,
        };
      });
    } else if (valueAnalysis === 1) {
      chartData = y?.map(({ timestamp, value }) => {
        return {
          TIMESTAMP: timestamp,
          Y: value,
        };
      });
    } else {
      chartData = z.map(({ timestamp, value }) => {
        return {
          TIMESTAMP: timestamp,
          Z: value,
        };
      });
    }

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(chartData);
        break;
      case "Download as PDF":
        if (chartRef) {
          reportDataWithGraph(
            chartRef,
            chartData,
            devices.deviceName,
            devices.Customer.name,
            "Vibration Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>

      <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
        <CardTwo
          title=" X Axis"
          amount={dashboardSts && dashboardSts.x ? `${dashboardSts.x} ` : "0"}
        />
        <CardTwo
          title="Y Axis"
          amount={dashboardSts && dashboardSts.y ? `${dashboardSts.y} ` : "0"}
        />
        <CardTwo
          title="Z Axis"
          amount={dashboardSts && dashboardSts.z ? `${dashboardSts.z} ` : "0"}
        />
      </div>
      <div className="border border-gray-400 rounded-md p-4">
        <div className="relative">
          <p className="mb-[20px]">
            {valueAnalysis === 0
              ? "X Axis "
              : valueAnalysis === 1
              ? "Y Axis "
              : "Z Axis "}{" "}
            Vibration data variation with time (between{" "}
            {last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                " to " +
                last24HoursData[0].timestamp
              : " "}
            )
          </p>
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as XLS",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        {/* <ButtonGroup
          variant="outlined"
          color="default"
          aria-label="Basic button group"
        >
          <Button
          variant="outlined"
          style={valueAnalysis === 0 ? { backgroundColor: "#dadada" } : {}}
            onClick={() => setvalueAnalysis(0)}
          >
            X Axis
          </Button>
          <Button
            variant="outlined"
            style={valueAnalysis === 1 ? { backgroundColor: "#dadada" } : {}}
            onClick={() => setvalueAnalysis(1)}
          >
            Y Axis
          </Button>
          <Button
          variant="outlined"
          style={valueAnalysis === 2 ? { backgroundColor: "#dadada" } : {}}
            onClick={() => setvalueAnalysis(2)}
          >
            Z Axis
          </Button>
        </ButtonGroup> */}
        <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
          <li className="focus-within:z-10">
            <a
              href="#"
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
              aria-current="page"
              style={valueAnalysis === 0 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setvalueAnalysis(0)}
            >
              X Axis
            </a>
          </li>

          <li className="focus-within:z-10">
            <a
              href="#"
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
              style={valueAnalysis === 1 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setvalueAnalysis(1)}
            >
              Y Axis
            </a>
          </li>

          <li className="focus-within:z-10">
            <a
              href="#"
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
              style={valueAnalysis === 2 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setvalueAnalysis(2)}
            >
              Z Axis
            </a>
          </li>
        </ul>

        <div className="mt-7 mb-5">
          <div ref={chartRef || ""}>
            <LineChart
              yAxisName={
                valueAnalysis === 0
                  ? "X Axis"
                  : valueAnalysis === 1
                  ? "Y Axis"
                  : "Z Axis"
              }
              xAxisName="Data"
              data={
                valueAnalysis === 0
                  ? xlinechart
                  : valueAnalysis === 1
                  ? ylinechart
                  : zlinechart
              }
              stroke={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardVibration2;
