import React, { useState, useEffect, useRef } from 'react';

interface SignalStrengthProps {
  percentage?: number;
}

const SignalStrength: React.FC<SignalStrengthProps> = ({ percentage = 0 }) => {
  
  const containerRef = useRef<HTMLDivElement>(null);
  const [internalPercentage, setInternalPercentage] = useState(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (internalPercentage < percentage) {
        setInternalPercentage((prevPercentage) => prevPercentage + 1);
      } else if (internalPercentage > percentage) {
        setInternalPercentage((prevPercentage) => prevPercentage - 1);
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [percentage, internalPercentage]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, []);

  const numberOfElements = 10; // Adjust this based on the number of bars you want
  const barHeight = containerHeight / numberOfElements;

  const divElements = Array.from(Array(numberOfElements).keys()).map((index) => (
    <div
      key={index}
      className={`bg-blue-300 border-b-4 border-white w-full ${index + 1 <= (internalPercentage * numberOfElements) / 100 ? '' : 'hidden'}`}
      style={{ height: `${barHeight}px` }}
    ></div>
  ));

  return (
    <div className='flex flex-col items-center'>
      <p className='text-sky-700 font-medium mb-2 '>SIGNAL STRENGTH</p>
      <div
        ref={containerRef} className='w-[67px] h-[190px]'
        style={{ overflow: 'auto' }}
      >
        {/* <p className='text-blue-700 text-center'>{percentage}</p> */}
        <div className='rotate-180 h-full ' style={{ border: '1px solid blue' }}>
          {divElements}
        </div>
      </div>
    </div>
  );
};

export default SignalStrength;

