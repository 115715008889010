import { Alert, PaginatedAlert } from "../../types/AlertTypes";

export interface AlertState {
  alert: PaginatedAlert;
}

export type AlertAction =
  | { type: "SET_ALERT"; payload: PaginatedAlert }
  | { type: "ADD_ALERT"; payload: { alert: Alert; totalItems: number } }
  | { type: "UPDATE_ALERT_STATUS"; payload: { id: number; status: boolean, key: string } }
  | {
    type: "UPDATE_ALERT";
    payload: { id: number | undefined; alert: Alert };
  };

export default function AlertReducer(
  state: AlertState,
  action: AlertAction
): AlertState {
  const { type, payload } = action;
  switch (type) {
    case "SET_ALERT":
      return { ...state, alert: payload };
    case "ADD_ALERT":
      const { alert: newAlert, totalItems } = payload;
      return {
        ...state,
        alert: {
          ...state.alert,
          alert: [newAlert, ...state.alert.alert],
          totalItems: totalItems + 1,
        },
      };
    case "UPDATE_ALERT_STATUS":

      const { id, status, key } = payload;

      const alertToUpdate = state.alert.alert.find((alert) => alert.id === id);

      if (alertToUpdate) {
        if (key === "SMS") {
          alertToUpdate.smsStatus = status;
        } else if (key === "EMAIL") {
          alertToUpdate.emailStatus = status
        }



        // Clone the devices array to trigger a state update
        const updatedAlertAfterStatusUpdate = [...state.alert.alert];

        // Update the state with the new devices array
        return {
          ...state,
          alert: {
            ...state.alert,
            alert: updatedAlertAfterStatusUpdate,
          },
        };
      }

      return state;
    case "UPDATE_ALERT":
      const { id: updateId, alert } = action.payload;
      const updatedAlert = state.alert.alert.map((a) =>
        a.id === updateId ? { ...a, ...alert } : a
      );
      return {
        ...state,
        alert: {
          ...state.alert,
          alert: updatedAlert,
        },
      };
    default:
      return state;
  }
}
