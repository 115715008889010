import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
import {
  addNewAlert,
  updateAlerts,
} from "../../app-context/alert-context/AlertAction";
import { useAlertState } from "../../app-context/alert-context/AlertState";
import { Alert } from "../../types/AlertTypes";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";

interface AddUserModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: Alert;
  uniqueId: string;
}

const conditions = [
  { label: "Greater than >", value: ">" },
  { label: "Greater than equal >=", value: ">=" },
  { label: "Less than <", value: "<" },
  { label: "Less than equal <=", value: "<=" },
  { label: "Equal ==", value: "===" },
  { label: "Not equal !=", value: "!==" },
];

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddAlertForm: React.FC<AddUserModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {
  const { users } = useUserState();
  const { dispatch, alert } = useAlertState();
  const [tags, setTags] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);
  const [formData, setFormData] = useState({
    customerId: users.user.customerId,
    deviceId: "",
    tagId: "",
    smsStatus: false,
    emailStatus: false,
    operand: "",
    alertName: "",
  });

  const [fieldSets, setFieldSets] = useState([
    { phone: "", email: "", description: "", value: "" },
  ]);

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "deviceId") {
      fetchTagBasedONDeviceID(value);
    }
  };

  const fetchData = async () => {
    try {
      const response = (await http(`${baseUrl}/device?alldevice=1`)) as any;
      setDevices(response.devices);
      console.log('response',response)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTagBasedONDeviceID = async (deviceId: number) => {
    try {
      const response = (await http(
        `${baseUrl}/tags?deviceId=${deviceId}`
      )) as any;
      setTags(response.tags);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  useEffect(() => {
    if (uniqueId === "EDIT" && data) {
      console.log(data);
      id = data.id;
      setFormData(data as any);
      fetchTagBasedONDeviceID(data ? data.deviceId : 0);

      // Set the fieldSets based on the data.level if available
      if (data.level) {
        const levels = Object.keys(data.level).map((key) => data.level[key]);
        setFieldSets(levels);
      }
    }
    fetchData();
  }, [uniqueId, data]);

  const transformFieldSets = () => {
    const transformed: any = {};
    fieldSets.forEach((fieldSet, index) => {
      transformed[`level${index + 1}`] = fieldSet;
    });
    return transformed;
  };

  const handleSubmit = async () => {
    const transformedFieldSets = transformFieldSets();
    const payload = { ...formData, level: transformedFieldSets };
    try {
      if (uniqueId === "ADD") {
        const resp = (await http(baseUrl + `/alert`, "POST", payload)) as any;
        addNewAlert(dispatch, {
          alert: resp.alert,
          totalItems: alert.totalItems,
        });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/alert?id=${id}`,
          "PUT",
          payload
        )) as any;
        updateAlerts(dispatch, { id: id, alert: resp.alert });
        toast.success(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldSetChange = (index: any, event: any) => {
    const { name, value } = event.target;
    const newFieldSets: any = [...fieldSets];
    newFieldSets[index][name] = value;
    setFieldSets(newFieldSets);
  };

  const handleAddFieldSet = () => {
    setFieldSets([...fieldSets, { phone: "", email: "", description: "", value: "" }]);
  };

  const handleRemoveFieldSet = (index: any) => {
    const newFieldSets = fieldSets.filter((_, i) => i !== index);
    setFieldSets(newFieldSets);
  };

  return (
    <>
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          <h2 className="font-bold">
            {uniqueId === "ADD" ? "Alert Creation" : "Update Alert"}
          </h2>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Alert"
            placeholder="Alert Name"
            fullWidth
            margin="normal"
            name="alertName"
            value={formData.alertName}
            onChange={handleChange}
            sx={{ width: "100%", height: "48px" }}
            inputProps={{
              style: {
                height: "20px",
                padding: "16px 12px",
              },
            }}
          />
          <FormControl sx={{ marginTop: "10px" }} fullWidth>
            <InputLabel
              id="selectdevice"
              sx={{ display: "flex", alignItems: "center", height: "20px" }}
            >
              Select Device
            </InputLabel>
            <Select
              labelId="selectdevice"
              id="select-customer"
              value={formData.deviceId}
              name="deviceId"
              label="Select Device"
              onChange={handleChange}
              sx={{ width: "100%", height: "48px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {devices &&
                devices.map((d: any) => (
                  <MenuItem
                    key={d.id}
                    value={d.id}
                    style={{
                      paddingLeft: "10px",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    {d.deviceName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <div className="flex mt-5">
            <FormControl
              className="flex-grow"
              sx={{ marginRight: "10px", minWidth: "calc(50% - 5px)" }}
            >
              <InputLabel
                sx={{ display: "flex", alignItems: "center", height: "20px" }}
                id="selecttag"
              >
                Select Parameter
              </InputLabel>
              <Select
                labelId="selecttag"
                id="select-tag"
                value={formData.tagId}
                name="tagId"
                label="Select Parameter"
                onChange={handleChange}
                sx={{ width: "100%", height: "48px" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {tags &&
                  tags.map((d: any) => (
                    <MenuItem
                      key={d.tagid}
                      value={d.tagid}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingLeft: "10px",
                      }}
                    >
                      {d.Tag.tagname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              className="flex-grow"
              sx={{ minWidth: "calc(50% - 5px)" }}
            >
              <InputLabel
                sx={{ display: "flex", alignItems: "center", height: "20px" }}
                id="selectcondition"
              >
                Select Condition
              </InputLabel>
              <Select
                labelId="selectcondition"
                id="select-condition"
                value={formData.operand}
                name="operand"
                label="Select Condition"
                onChange={handleChange}
                sx={{ width: "100%", height: "48px" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {conditions.map((condition, index) => (
                  <MenuItem
                    key={index}
                    value={condition.value}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingLeft: "10px",
                    }}
                  >
                    {condition.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {fieldSets.map((fieldSet, index) => (
            <div key={index}>
              <div className="relative flex justify-between items-center">
                <h3 className="font-bold mt-1">Level {index + 1}</h3>
                {index > 0 && (
                  <IconButton onClick={() => handleRemoveFieldSet(index)}>
                    <ClearIcon />
                  </IconButton>
                )}
              </div>

              <div className="flex flex-wrap space-y-1">
                <TextField
                  label="Email"
                  placeholder="Email Id"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={fieldSet.email}
                  onChange={(event) => handleFieldSetChange(index, event)}
                  inputProps={{
                    style: {
                      height: "20px",
                      padding: "16px 12px",
                    },
                  }}
                />
                <TextField
                  label="Phone"
                  placeholder="Phone"
                  fullWidth
                  margin="normal"
                  name="phone"
                  value={fieldSet.phone}
                  onChange={(event) => handleFieldSetChange(index, event)}
                  inputProps={{
                    style: {
                      height: "20px",
                      padding: "16px 12px",
                    },
                  }}
                />
              </div>
              <div className="flex mt-2 space-x-1">
                <TextField
                  label="Set Value"
                  placeholder="Set Value"
                  fullWidth
                  margin="normal"
                  name="value"
                  value={fieldSet.value}
                  onChange={(event) => handleFieldSetChange(index, event)}
                  inputProps={{
                    style: {
                      height: "20px",
                      padding: "16px 12px",
                    },
                  }}
                />
                <TextField
                  label="Description"
                  placeholder="Enter the description"
                  fullWidth
                  margin="normal"
                  name="description"
                  value={fieldSet.description}
                  onChange={(event) => handleFieldSetChange(index, event)}
                  inputProps={{
                    style: {
                      height: "20px",
                      padding: "16px 12px",
                    },
                  }}
                />
              </div>
            </div>
          ))}

          <div className="flex justify-end mt-2 ">
            {fieldSets.length !== 3 && (
              <Button
                onClick={handleAddFieldSet}
                sx={{
                  backgroundColor: "#E0E0E0",
                  color: "#1D2939",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#D5D5D5",
                  },
                }}
                startIcon={<AddIcon sx={{ fontSize: 14 }} />}
              >
                Add Level
              </Button>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#039855",
              color: "#FFFFFF",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#D5D5D5",
              },
            }}
            onClick={handleSubmit}
          >
            {uniqueId === "ADD" ? "Submit" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAlertForm;
